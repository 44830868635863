import { getLocalDateFormatted, getLocalTimeFormatted } from 'src/utils/time';
import { getCameraName } from 'src/utils/utils';

export const Copy = (eventId, repInfo, eventData) => {
  const date = getLocalDateFormatted({
    timestampUTC: eventData?.eventTimestamp,
    locationOffsetMinutes: eventData?.locationOffset,
  });
  const time = getLocalTimeFormatted({
    timestampUTC: eventData?.eventTimestamp,
    locationOffsetMinutes: eventData?.locationOffset,
  });
  const datetime = `${date} at ${time}`;
  return {
    canceled_rep: `Motion Event was cancelled by Agent ${repInfo?.data?.firstName} on ${datetime} - No cause for alarm.`,
    canceled_ai: `Motion Event was cancelled by AI on ${datetime} - No cause for alarm.`,
    canceled_user_app: `Motion Event was cancelled by User on ${datetime} - No cause for alarm.`,
    canceled_safe_word: `Motion Event was cancelled by Safeword on ${datetime} - No cause for alarm.`,
    agent_handled: `Motion Event was handled by Agent ${repInfo?.data?.firstName} on ${datetime} - A delivery was made.  You have a package.`,
    dispatched_police: `Police dispatched for Motion Event was initiated on ${datetime} - We will call the number on file immediately.`,
    dispatched_fire: `Fire dispatched for Motion Event was initiated on ${datetime} - We will call the number on file immediately.`,
    dispatched_medical: `Medical dispatched for Motion Event was initiated on ${datetime} - We will call the number on file immediately.`,
    canceled_by_customer: `Motion Event was cancelled by Customer on ${datetime} - No cause for alarm.`,
    paused_monitoring: `Motion Event was paused monitoring on ${datetime} - No cause for alarm.`,
  };
};

function mapSubTypeToText(time, camera, type) {
  const typeMap = {
    default: {
      title: 'Motion Detected',
      body: `We reviewed a motion detection event at ${time}`,
    },
    common_event: {
      title: 'Common Activity',
      body: `Common activity detected at ${time}. No issues observed.`,
    },
    familiar_person_on_property: {
      title: 'Person on Property',
      body: `A person who appeared to match a familiar face was detected at ${time}.`,
    },
    unfamiliar_person_on_property_delivery: {
      title: 'Delivery',
      body: `A mail, package or food delivery was dropped off at ${time}.`,
    },
    unfamiliar_person_on_property_entry: {
      title: 'Unfamiliar - Agent Engaged',
      body: `We spoke with an unfamiliar person on your property at ${time}.`,
    },
    unfamiliar_person_on_property_outdoor_service: {
      title: 'Outdoor Service',
      body: `A person performed an outdoor service at ${time}.`,
    },
    unfamiliar_person_on_property_escalation: {
      title: 'Escalation',
      body: `There is an Urgent Event occuring on your property. We have {describe agent actions, i.e. called emergency contacts}.`,
    },
    interior_camera_placement: {
      title: `Camera Placement Issue - ${camera}`,
      body: `Your outdoor camera appeared to be indoors at ${time}. Move it outside for Outdoor Protection.`,
    },
    urgent_events: {
      title: 'Urgent Events',
      body: `There is an Urgent Event occuring on your property. We have {describe agent actions, i.e. called emergency contacts}.`,
    },
    emergency_events: {
      title: 'Emergency Events',
      body: `There is an Emergency Event occuring on your property. We have {describe agent actions, i.e. dispatched P/F/M}.`,
    },
    system_disarm: {
      title: 'System Disarm',
      body: `Your system was disarmed at ${time}. We'll be back when your system is armed!`,
    },
    outdoor_service_identified_person: {
      title: 'Outdoor Service',
      body: `A person performed an outdoor service at ${time}.`,
    },
    uniformed_authorities: {
      title: 'Uniformed Authorities',
      body: `Uniformed authorities were on your property at ${time}.`,
    },
    unattended_minor: {
      title: 'Unattended Minor',
      body: `An unattended minor was detected on your property at ${time}.`,
    },
    non_threatening_deterrence: {
      title: 'Unknown Person Engaged',
      body: `We instructed an unfamiliar person to leave your property at ${time}.`,
    },
    unknown_person_no_response: {
      title: 'Unknown Person',
      body: `An unfamiliar person was observed on your property at ${time}.`,
    },
    unknown_person_camera_failure: {
      title: 'Unknown Person',
      body: `An unfamiliar person was observed on your property at ${time} in a recorded video.`,
    },
    unknown_person_other: {
      title: 'Unknown Person',
      body: `An unfamiliar person was observed on your property at ${time}.`,
    },
    car_on_property: {
      title: 'Car on Property',
      body: `A car stopped on your property at ${time}.`,
    },
    unknown_person_out_of_range: {
      title: 'Unknown Person',
      body: `An unfamiliar person was observed on your property at ${time}.`,
    },
  };

  return (
    typeMap[type] || {
      title: 'Motion Detected',
      body: 'No Data For this Disposition Subtype',
    }
  );
}

export function getCopyFromDisposition(eventData, dispositionSubType: string) {
  const time = getLocalTimeFormatted({
    timestampUTC: eventData?.eventTimestamp,
    locationOffsetMinutes: eventData?.locationOffset,
  });
  const cameraName = getCameraName(eventData?.info);
  return mapSubTypeToText(time, cameraName, dispositionSubType);
}
