import { Attributes } from '@opentelemetry/api';

export const METRIC_SYSTEM = 'pcs';
export const METRIC_SOURCE = 'agent_portal';

/**
 * Metrics are sent to Grafana and consist of a single data point in time
 * Milestones are sent to Kafka/Data Lake and give a more complete view of the system at specified workflow transitions
 */
export enum MeasurementType {
  METRIC = 'metric',
  MILESTONE = 'milestone',
}

/**
 * The primary key for each measurement
 */
export enum MeasurementKey {
  EVENT = 'event',
  AGENT = 'agent', // specialist
}

/**
 * Counters increase linearly each time they are recorded
 * Histograms sample a value over time
 */
export enum MetricType {
  COUNTER = 'counter',
  HISTOGRAM = 'histogram',
  GAUGE = 'gauge',
}

/**
 * Data tags to be included with Milestones, or as Attributes for Metrics
 */
export enum Tag {
  LOCATION_ID = 'location_id',
  ACTIVE_AGENT_ID = 'active_agent_id',
  CAMERA_UUID = 'camera_uuid',
  CAMERA_MODEL = 'camera_model',
  CAMERA_FIRMWARE = 'camera_firmware_version',
  REASON = 'reason',
  DISPOSITION_ACTION = 'disposition_action',
  CANCEL_REASON = 'cancel_reason',
  DROP_REASON = 'drop_reason',
  AUTO_FILTER_REASON = 'auto_filter_reason',
  OTHER_EVENT_ID = 'other_event_id',
  PREVIOUS_STATE = 'previous_state',
  HOLD_REASON = 'hold_reason',
  CAMERA_BACKEND_TYPE = 'camera_backend_type',
  ERROR_TYPE = 'error_type',
  DECISION_MADE = 'decision_made',
  MODEL_MAKING_DECISION = 'model_making_decision',
  THREAT_LEVEL = 'threat_level',
  PREVIOUS_THREAT_LEVEL = 'previous_threat_level',
  NEW_THREAT_LEVEL = 'new_threat_level',
  TOTAL_AVAILABLE_SLOTS = 'total_available_slots',
  TOTAL_FILLED_SLOTS = 'total_filled_slots',
  TIME_SPENT_IN_FEED = 'time_spent_in_feed',
  ACTION_NAME = 'action_name',
  PREVIOUS_SCREEN = 'previous_screen',
  DETERRENT_TYPE = 'deterrent_type',
}

export enum CancelReason {
  DUPLICATE = 'duplicate_event',
  DELIVERY = 'delivery',
  NO_PERSON = 'no_person',
  PASSERBY = 'passerby',
  OUTDOOR_SERVICE = 'outdoor_service',
  HOUSEHOLD_ACTIVITY = 'household_activity',
  TECHNICAL_ISSUE = 'technical_issue',
  WEATHER = 'weather_event',
  ADJACENT_ACTIVITY = 'adjacent_activity',
  OTHER = 'other',
  PASSERBY_IN_CAR = 'passerby_in_car',
}

/**
 * Complete list of measurement Instruments, with SimpliSafe-globally-unique names (pcs prefix)
 *
 * For Metrics, these will be created as OTEL Instruments and sent directly to the SimpliSafe OTEL collector service for ingestion into Grafana
 * For Milestones, these will be sent to the PCS Admin Backend (pre-crime-server/admin) which will handle ingestion into the Data Lake
 */
export enum INSTRUMENTS {
  EVENT_RECEIVED = 'event_received',
  EVENT_IGNORED = 'event_ignored',
  EVENT_PROPAGATION_DELAY = 'event_propagation_delay',
  EVENT_AUTO_FILTERED = 'event_auto_filtered',
  EVENT_TIME_TO_AUTO_FILTER = 'event_time_to_auto_filter',
  EVENT_HELD = 'event_held',
  EVENT_UNHELD = 'event_unheld',
  EVENT_GOT_FIRST_FRAME = 'event_got_first_frame',
  EVENT_TIME_TO_FIRST_FRAME_FOR_PROCESSING = 'event_time_to_first_frame_for_processing',
  EVENT_TIME_TO_AI_DECISION = 'event_time_to_ai_decision',
  EVENT_TIME_SPENT_IN_FEED = 'event_time_spent_in_feed',
  EVENT_VIDEO_PROCESSING_ERROR = 'event_video_processing_error',
  EVENT_COMPLETED_INITIAL_VIDEO_PROCESSING = 'event_completed_initial_video_processing',
  EVENT_TIME_TO_COMPLETE_INITIAL_VIDEO_PROCESSING = 'event_time_to_complete_initial_video_processing',
  EVENT_READY_TO_SEND_TO_AGENT = 'event_ready_to_send_to_agent',
  EVENT_THREAT_LEVEL_CHANGED = 'event_threat_level_changed',
  EVENT_PRESENTED_TO_AGENT = 'event_presented_to_agent',
  EVENT_EXITED_FEED = 'event_exited_feed',
  EVENT_STARTED_VERIFICATION = 'event_started_verification',
  EVENT_ENDED_VERIFICATION = 'event_ended_verification',
  EVENT_VERIFICATION_DURATION = 'event_verification_duration',
  EVENT_STARTED_FOLLOWUP = 'event_started_followup',
  EVENT_ENDED_FOLLOWUP = 'event_ended_followup',
  EVENT_FOLLOWUP_DURATION = 'event_followup_duration',
  EVENT_DISPOSITIONED = 'event_dispositioned',
  EVENTS_RETURNED_IN_TAKE = 'events_returned_in_take',
  AGENT_ENTERED_FEED = 'agent_entered_feed',
  AGENT_EXITED_FEED = 'agent_exited_feed',
  AGENT_TIME_SPENT_IN_FEED = 'agent_time_spent_in_feed',
  AGENT_FEED_SLOT_OCCUPANCY_CHANGED = 'agent_feed_slot_occupancy_changed',
  AGENT_WEBSOCKET_CONNECTED = 'agent_websocket_connected',
  AGENT_WEBSOCKET_DISCONNECTED = 'agent_websocket_disconnected',
  AGENT_ENTERED_WORK_STATE = 'agent_entered_work_state',
  AGENT_EXITED_WORK_STATE = 'agent_exited_work_state',
  AGENT_DETERRENT_ACTIVATED = 'agent_deterrent_activated',
  AGENT_2_WAY_AUDIO_UNMUTE = 'agent_2_way_audio_unmute',
  AGENT_2_WAY_AUDIO_MUTE = 'agent_2_way_audio_mute',
  AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS = 'agent_2_way_audio_connection_success',
  AGENT_2_WAY_AUDIO_CONNECTION_FAILURE = 'agent_2_way_audio_connection_failure',
  AGENT_2_WAY_AUDIO_DURATION = 'agent_2_way_audio_duration',
  EVENTS_IN_NEW_STATE = 'events_in_new_state',
}

export const MeasurementDescription = {
  [INSTRUMENTS.EVENT_RECEIVED]:
    'When the event is received by pcs-processsing from RabbitMQ.',
  [INSTRUMENTS.EVENT_IGNORED]:
    "When the event is dropped by pcs-processsing because it won't generate any processing (e.g. system status changes, non-motion-recording events, etc.)",
  [INSTRUMENTS.EVENT_PROPAGATION_DELAY]:
    'When the event is received by pcs-processing from RabbitMQ.',
  [INSTRUMENTS.EVENT_AUTO_FILTERED]:
    'When the event is auto-handled in pcs-processing as a result of AI filtering or one of the following: unarmed state, non-enrolled camera, location outside of service hours, active intrusion handling.',
  [INSTRUMENTS.EVENT_TIME_TO_AUTO_FILTER]:
    'When the event is auto-handled in pcs-processing as a result of AI filtering or one of the following: unarmed state, non-enrolled camera, location outside of service hours, active intrusion handling.',
  [INSTRUMENTS.EVENT_HELD]:
    'When the event is held due to another event from the same location in V&R/Followup.',
  [INSTRUMENTS.EVENT_UNHELD]: 'When the event is released from a hold.',
  [INSTRUMENTS.EVENT_GOT_FIRST_FRAME]:
    'When the first frame is received from KVS/TEP/(eventually MIST).',
  [INSTRUMENTS.EVENT_TIME_TO_FIRST_FRAME_FOR_PROCESSING]:
    'Time to receive the first frame from when we started video processing.',
  [INSTRUMENTS.EVENT_TIME_TO_AI_DECISION]: 'Time to make an AI decision.',
  [INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED]:
    'Elapsed milliseconds that the event was in the feed',
  [INSTRUMENTS.EVENT_VIDEO_PROCESSING_ERROR]:
    'An error occurred processing the event.',
  [INSTRUMENTS.EVENT_COMPLETED_INITIAL_VIDEO_PROCESSING]:
    'We completed processing the initial 5 seconds of video (regardless of whether a decision was made).',
  [INSTRUMENTS.EVENT_TIME_TO_COMPLETE_INITIAL_VIDEO_PROCESSING]:
    'How long did it take for us to complete processing the initial 5 seconds of video.',
  [INSTRUMENTS.EVENT_READY_TO_SEND_TO_AGENT]:
    'We have completed processing and are ready to send this to an agent.',
  [INSTRUMENTS.EVENT_THREAT_LEVEL_CHANGED]:
    'The threat level of the event changed.',
  [INSTRUMENTS.EVENT_PRESENTED_TO_AGENT]:
    'When the event was presented to the agent.',
  [INSTRUMENTS.EVENT_EXITED_FEED]: `Any time an event exits the feed, which can be due to any of the following mechanisms:
        - Canceled from feed (including if the system cancels an event automatically, such as from a disarm or intrusion handle)
        - Moved to V&R from feed
        - Moved to HOLD status while in feed
        - Bounced from feed (see definition below)
        - Expired while in feed
        - Logged out while on feed
        - Tab refresh while in feed
        - Tab/window close while in feed
        An event is considered "bounced" from feed when an event is one of multiple in feed, and the specialist starts verifying a different event from that set than this one.
        Example: Two events in feed, A and B. Specialist clicks in to verify and respond for event A. Event B is considered "bounced".`,
  [INSTRUMENTS.EVENT_STARTED_VERIFICATION]:
    'When a specialist started verifying the event (i.e. took it into verify & respond).',
  [INSTRUMENTS.EVENT_ENDED_VERIFICATION]: `When a specialist stopped verifying the event (i.e. left V&R), due to any of the following mechanisms:  
        - Canceled from V&R (including if the system cancels an event automatically, such as from a disarm or intrusion handle)  
        - Moved to follow up from V&R  
        - Moved to HOLD status while in V&R (if possible)  
        - Logged out while on V&R  
        - Tab refresh while on V&R  
        - Tab/window close while on V&R`,
  [INSTRUMENTS.EVENT_VERIFICATION_DURATION]: 'How long was an event in V&R.',
  [INSTRUMENTS.EVENT_STARTED_FOLLOWUP]:
    'When a specialist took an event into followup.',
  [INSTRUMENTS.EVENT_ENDED_FOLLOWUP]: `When a specialist completed following up on an event (i.e. left followup), due to any of the following mechanisms:
        - Canceled from FU (including if the system cancels an event automatically, such as from a disarm or intrusion handle)
        - Completed event from FU
        - Logout while on FU
        - Tab refresh while on FU
        - Tab/window close while on FU`,
  [INSTRUMENTS.EVENT_FOLLOWUP_DURATION]: 'How long was an event in followup.',
  [INSTRUMENTS.EVENT_DISPOSITIONED]:
    'When an event was dispositioned, either by the system or a specialist.',
  [INSTRUMENTS.EVENTS_RETURNED_IN_TAKE]:
    'How many events did we give back to an agent when they initiated a take request.',
  [INSTRUMENTS.AGENT_ENTERED_FEED]: `Sent upon the completion of the initial take that takes place after any of:
        - Accept new is turned on from off
        - Return to feed from VR
        - Return to feed from FU
        - Feed is loaded (or refreshed) and Accept New is on as the specialist's default`,
  [INSTRUMENTS.AGENT_EXITED_FEED]: `Sent after each of the following:
        - Accept new is turned off from turned on while in feed
        - Event taken into VR from feed
        - Logout while in feed and Accept New is on
        - Tab refreshed while in feed and Accept New is on 
        - Tab/window closed while in feed and Accept New is on
        - Timeout (an event times out/expires in feed kicking the specialist out)`,
  [INSTRUMENTS.AGENT_FEED_SLOT_OCCUPANCY_CHANGED]:
    "Sent any time an event(s) is added to, or removed from, a specialist's slot(s), without the specialist leaving or entering the feed.",
  [INSTRUMENTS.AGENT_WEBSOCKET_CONNECTED]: 'An agent websocket connected.',
  [INSTRUMENTS.AGENT_WEBSOCKET_DISCONNECTED]:
    'An agent websocket disconnected.',
  [INSTRUMENTS.AGENT_ENTERED_WORK_STATE]: `A specialist is in a work state when any of the following are true:
        - In Feed with Accept New on (regardless of whether they have any events in slots)
        - Handling an event in VR (verify + respond)
        - Handling an event in FU (follow up)
        This metric should send if a specialist enters any of those states AND was not already in a work state.`,
  [INSTRUMENTS.AGENT_EXITED_WORK_STATE]: `This metric should send if a specialist is in a work state (see definition above) and leaves a work state. 
        This could be by:
        - Turning off Accept New while in Feed
        - Logging out while in Feed with Accept New on, or while on VR, or while on FU
        - Tab/window closed while in Feed with Accept New on, or while on VR, or while on FU`,
  [INSTRUMENTS.AGENT_DETERRENT_ACTIVATED]:
    'Any time an agent activates a non-2WA deterrent.',
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE]:
    'Any time an agent unmutes a camera to activate 2WA. This could be by clicking to unmute (full duplex cams) or pressing and holding the mic button (half duplex VDP).',
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE]:
    'Any time an agent mutes a camera to deactivate 2WA. This could be by clicking to mute (full duplex cams) or releasing the mic button (half duplex VDP).',
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS]:
    'When the 2WA connection is established.',
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE]:
    'When the 2WA connection fails for some reason.',
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_DURATION]: 'When the 2WA connection is ended.',
  [INSTRUMENTS.EVENTS_IN_NEW_STATE]:
    'Incremented when an event is placed in the new state, decremented when it leaves the new state.',
};

// Base Interface for all Measurements
export interface IMeasurement {
  name: INSTRUMENTS;
  types: MeasurementType[];
  description: string;
  tags: Tag[];
  key: MeasurementKey;
  metricType: MetricType;
}

// Event Received
export interface IEventReceived extends IMeasurement {
  name: INSTRUMENTS.EVENT_RECEIVED;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_RECEIVED];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Ignored
export interface IEventIgnored extends IMeasurement {
  name: INSTRUMENTS.EVENT_IGNORED;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_IGNORED];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.DROP_REASON
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Propagation Delay
export interface IEventPropagationDelay extends IMeasurement {
  name: INSTRUMENTS.EVENT_PROPAGATION_DELAY;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_PROPAGATION_DELAY];
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Event Auto Filtered
export interface IEventAutoFiltered extends IMeasurement {
  name: INSTRUMENTS.EVENT_AUTO_FILTERED;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_AUTO_FILTERED];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.AUTO_FILTER_REASON
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Time to Auto Filter
export interface IEventTimeToAutoFilter extends IMeasurement {
  name: INSTRUMENTS.EVENT_TIME_TO_AUTO_FILTER;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_TIME_TO_AUTO_FILTER];
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.AUTO_FILTER_REASON];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Event Held
export interface IEventHeld extends IMeasurement {
  name: INSTRUMENTS.EVENT_HELD;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_HELD];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.OTHER_EVENT_ID,
    Tag.PREVIOUS_STATE,
    Tag.ACTIVE_AGENT_ID,
    Tag.HOLD_REASON
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Unheld
export interface IEventUnheld extends IMeasurement {
  name: INSTRUMENTS.EVENT_UNHELD;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_UNHELD];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.OTHER_EVENT_ID,
    Tag.PREVIOUS_STATE,
    Tag.ACTIVE_AGENT_ID,
    Tag.HOLD_REASON
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Got First Frame
export interface IEventGotFirstFrame extends IMeasurement {
  name: INSTRUMENTS.EVENT_GOT_FIRST_FRAME;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_GOT_FIRST_FRAME];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.CAMERA_BACKEND_TYPE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Time to First Frame for Processing
export interface IEventTimeToFirstFrameForProcessing extends IMeasurement {
  name: INSTRUMENTS.EVENT_TIME_TO_FIRST_FRAME_FOR_PROCESSING;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_TIME_TO_FIRST_FRAME_FOR_PROCESSING];
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.CAMERA_BACKEND_TYPE];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Event Time to AI Decision
export interface IEventTimeToAIDecision extends IMeasurement {
  name: INSTRUMENTS.EVENT_TIME_TO_AI_DECISION;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_TIME_TO_AI_DECISION];
  tags: [
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.CAMERA_BACKEND_TYPE,
    Tag.DECISION_MADE,
    Tag.MODEL_MAKING_DECISION
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Event Time Spent in Feed
export interface IEventTimeSpentInFeed extends IMeasurement {
  name: INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED];
  tags: [];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Event Video Processing Error
export interface IEventVideoProcessingError extends IMeasurement {
  name: INSTRUMENTS.EVENT_VIDEO_PROCESSING_ERROR;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_VIDEO_PROCESSING_ERROR];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.ERROR_TYPE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Completed Initial Video Processing
export interface IEventCompletedInitialVideoProcessing extends IMeasurement {
  name: INSTRUMENTS.EVENT_COMPLETED_INITIAL_VIDEO_PROCESSING;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_COMPLETED_INITIAL_VIDEO_PROCESSING];
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Time to Complete Initial Video Processing
export interface IEventTimeToCompleteInitialVideoProcessing
  extends IMeasurement {
  name: INSTRUMENTS.EVENT_TIME_TO_COMPLETE_INITIAL_VIDEO_PROCESSING;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_TIME_TO_COMPLETE_INITIAL_VIDEO_PROCESSING];
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Event Ready to Send to Agent
export interface IEventReadyToSendToAgent extends IMeasurement {
  name: INSTRUMENTS.EVENT_READY_TO_SEND_TO_AGENT;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_READY_TO_SEND_TO_AGENT];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.THREAT_LEVEL
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Threat Level Changed
export interface IEventThreatLevelChanged extends IMeasurement {
  name: INSTRUMENTS.EVENT_THREAT_LEVEL_CHANGED;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_THREAT_LEVEL_CHANGED];
  tags: [Tag.PREVIOUS_THREAT_LEVEL, Tag.NEW_THREAT_LEVEL];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Presented to Agent
export interface IEventPresentedToAgent extends IMeasurement {
  name: INSTRUMENTS.EVENT_PRESENTED_TO_AGENT;
  types: [MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_PRESENTED_TO_AGENT];
  tags: [
    Tag.LOCATION_ID,
    Tag.ACTIVE_AGENT_ID,
    Tag.TOTAL_AVAILABLE_SLOTS,
    Tag.TOTAL_FILLED_SLOTS
  ];
  key: MeasurementKey.EVENT;
  metricType: null;
}

// Event Exited Feed
export interface IEventExitedFeed extends IMeasurement {
  name: INSTRUMENTS.EVENT_EXITED_FEED;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_EXITED_FEED];
  tags: [
    Tag.LOCATION_ID,
    Tag.ACTIVE_AGENT_ID,
    Tag.REASON,
    Tag.DISPOSITION_ACTION,
    Tag.TIME_SPENT_IN_FEED,
    Tag.CANCEL_REASON
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Started Verification
export interface IEventStartedVerification extends IMeasurement {
  name: INSTRUMENTS.EVENT_STARTED_VERIFICATION;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_STARTED_VERIFICATION];
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Ended Verification
export interface IEventEndedVerification extends IMeasurement {
  name: INSTRUMENTS.EVENT_ENDED_VERIFICATION;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_ENDED_VERIFICATION];
  tags: [
    Tag.LOCATION_ID,
    Tag.ACTIVE_AGENT_ID,
    Tag.REASON,
    Tag.DISPOSITION_ACTION,
    Tag.CANCEL_REASON
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Verification Duration
export interface IEventVerificationDuration extends IMeasurement {
  name: INSTRUMENTS.EVENT_VERIFICATION_DURATION;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_VERIFICATION_DURATION];
  tags: [];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Event Started Followup
export interface IEventStartedFollowup extends IMeasurement {
  name: INSTRUMENTS.EVENT_STARTED_FOLLOWUP;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_STARTED_FOLLOWUP];
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Ended Followup
export interface IEventEndedFollowup extends IMeasurement {
  name: INSTRUMENTS.EVENT_ENDED_FOLLOWUP;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_ENDED_FOLLOWUP];
  tags: [
    Tag.LOCATION_ID,
    Tag.ACTIVE_AGENT_ID,
    Tag.REASON,
    Tag.DISPOSITION_ACTION,
    Tag.CANCEL_REASON
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Event Followup Duration
export interface IEventFollowupDuration extends IMeasurement {
  name: INSTRUMENTS.EVENT_FOLLOWUP_DURATION;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_FOLLOWUP_DURATION];
  tags: [];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Event Dispositioned
export interface IEventDispositioned extends IMeasurement {
  name: INSTRUMENTS.EVENT_DISPOSITIONED;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_DISPOSITIONED];
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID, Tag.DISPOSITION_ACTION];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Events Returned in Take
export interface IEventsReturnedInTake extends IMeasurement {
  name: INSTRUMENTS.EVENTS_RETURNED_IN_TAKE;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENTS_RETURNED_IN_TAKE];
  tags: [];
  key: MeasurementKey.AGENT;
  metricType: MetricType.COUNTER;
}

// Agent Entered Feed
export interface IAgentEnteredFeed extends IMeasurement {
  name: INSTRUMENTS.AGENT_ENTERED_FEED;
  types: [MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_ENTERED_FEED];
  tags: [
    Tag.TOTAL_AVAILABLE_SLOTS,
    Tag.TOTAL_FILLED_SLOTS,
    Tag.ACTION_NAME,
    Tag.PREVIOUS_STATE
  ];
  key: MeasurementKey.AGENT;
  metricType: null;
}

// Agent Exited Feed
export interface IAgentExitedFeed extends IMeasurement {
  name: INSTRUMENTS.AGENT_EXITED_FEED;
  types: [MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_EXITED_FEED];
  tags: [
    Tag.TOTAL_AVAILABLE_SLOTS,
    Tag.TOTAL_FILLED_SLOTS,
    Tag.ACTION_NAME,
    Tag.PREVIOUS_STATE
  ];
  key: MeasurementKey.AGENT;
  metricType: null;
}

// Agent Feed Slot Occoupancy Changed
export interface IAgentFeedSlotOccoupancyChanged extends IMeasurement {
  name: INSTRUMENTS.AGENT_FEED_SLOT_OCCUPANCY_CHANGED;
  types: [MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_FEED_SLOT_OCCUPANCY_CHANGED];
  tags: [Tag.TOTAL_AVAILABLE_SLOTS, Tag.TOTAL_FILLED_SLOTS, Tag.ACTION_NAME];
  key: MeasurementKey.AGENT;
  metricType: null;
}

// Agent Websocket Connected
export interface IAgentWebsocketConnected extends IMeasurement {
  name: INSTRUMENTS.AGENT_WEBSOCKET_CONNECTED;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_WEBSOCKET_CONNECTED];
  tags: [];
  key: MeasurementKey.AGENT;
  metricType: MetricType.COUNTER;
}

// Agent Websocket Disconnected
export interface IAgentWebsocketDisconnected extends IMeasurement {
  name: INSTRUMENTS.AGENT_WEBSOCKET_DISCONNECTED;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_WEBSOCKET_DISCONNECTED];
  tags: [];
  key: MeasurementKey.AGENT;
  metricType: MetricType.COUNTER;
}

// Agent Entered Work State
export interface IAgentEnteredWorkState extends IMeasurement {
  name: INSTRUMENTS.AGENT_ENTERED_WORK_STATE;
  types: [MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_ENTERED_WORK_STATE];
  tags: [Tag.ACTION_NAME];
  key: MeasurementKey.AGENT;
  metricType: null;
}

// Agent Exited Work State
export interface IAgentExitedWorkState extends IMeasurement {
  name: INSTRUMENTS.AGENT_EXITED_WORK_STATE;
  types: [MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_EXITED_WORK_STATE];
  tags: [Tag.ACTION_NAME];
  key: MeasurementKey.AGENT;
  metricType: null;
}

// Agent Deterrent Activated
export interface IAgentDeterrentActivated extends IMeasurement {
  name: INSTRUMENTS.AGENT_DETERRENT_ACTIVATED;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_DETERRENT_ACTIVATED];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.ACTIVE_AGENT_ID,
    Tag.CAMERA_BACKEND_TYPE,
    Tag.DETERRENT_TYPE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Agent 2-Way Audio Unmute
export interface IAgent2WayAudioUnmute extends IMeasurement {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.ACTIVE_AGENT_ID,
    Tag.CAMERA_BACKEND_TYPE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Agent 2-Way Audio Mute
export interface IAgent2WayAudioMute extends IMeasurement {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.ACTIVE_AGENT_ID,
    Tag.CAMERA_BACKEND_TYPE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Agent 2-Way Audio Connection Success
export interface IAgent2WayAudioConnectionSuccess extends IMeasurement {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.ACTIVE_AGENT_ID,
    Tag.CAMERA_BACKEND_TYPE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Agent 2-Way Audio Connection Failure
export interface IAgent2WayAudioConnectionFailure extends IMeasurement {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE;
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.ACTIVE_AGENT_ID,
    Tag.CAMERA_BACKEND_TYPE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.COUNTER;
}

// Agent 2-Way Audio Connection Duration
export interface IAgent2WayAudioDuration extends IMeasurement {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_DURATION;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_DURATION];
  tags: [
    Tag.LOCATION_ID,
    Tag.CAMERA_UUID,
    Tag.CAMERA_MODEL,
    Tag.CAMERA_FIRMWARE,
    Tag.ACTIVE_AGENT_ID,
    Tag.CAMERA_BACKEND_TYPE
  ];
  key: MeasurementKey.EVENT;
  metricType: MetricType.HISTOGRAM;
}

// Events in New State
export interface IEventsInNewState extends IMeasurement {
  name: INSTRUMENTS.EVENTS_IN_NEW_STATE;
  types: [MeasurementType.METRIC];
  description: typeof MeasurementDescription[INSTRUMENTS.EVENTS_IN_NEW_STATE];
  tags: [];
  key: MeasurementKey.EVENT;
  metricType: MetricType.GAUGE;
}

// Collection interfaces that make accessing and documentation easy
export interface METRIC_INTERFACES {
  [INSTRUMENTS.EVENT_RECEIVED]: IEventReceived;
  [INSTRUMENTS.EVENT_IGNORED]: IEventIgnored;
  [INSTRUMENTS.EVENT_PROPAGATION_DELAY]: IEventPropagationDelay;
  [INSTRUMENTS.EVENT_AUTO_FILTERED]: IEventAutoFiltered;
  [INSTRUMENTS.EVENT_TIME_TO_AUTO_FILTER]: IEventTimeToAutoFilter;
  [INSTRUMENTS.EVENT_HELD]: IEventHeld;
  [INSTRUMENTS.EVENT_UNHELD]: IEventUnheld;
  [INSTRUMENTS.EVENT_GOT_FIRST_FRAME]: IEventGotFirstFrame;
  [INSTRUMENTS.EVENT_TIME_TO_FIRST_FRAME_FOR_PROCESSING]: IEventTimeToFirstFrameForProcessing;
  [INSTRUMENTS.EVENT_TIME_TO_AI_DECISION]: IEventTimeToAIDecision;
  [INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED]: IEventTimeSpentInFeed;
  [INSTRUMENTS.EVENT_VIDEO_PROCESSING_ERROR]: IEventVideoProcessingError;
  [INSTRUMENTS.EVENT_COMPLETED_INITIAL_VIDEO_PROCESSING]: IEventCompletedInitialVideoProcessing;
  [INSTRUMENTS.EVENT_TIME_TO_COMPLETE_INITIAL_VIDEO_PROCESSING]: IEventTimeToCompleteInitialVideoProcessing;
  [INSTRUMENTS.EVENT_READY_TO_SEND_TO_AGENT]: IEventReadyToSendToAgent;
  [INSTRUMENTS.EVENT_THREAT_LEVEL_CHANGED]: IEventThreatLevelChanged;
  [INSTRUMENTS.EVENT_PRESENTED_TO_AGENT]: IEventPresentedToAgent;
  [INSTRUMENTS.EVENT_EXITED_FEED]: IEventExitedFeed;
  [INSTRUMENTS.EVENT_STARTED_VERIFICATION]: IEventStartedVerification;
  [INSTRUMENTS.EVENT_ENDED_VERIFICATION]: IEventEndedVerification;
  [INSTRUMENTS.EVENT_VERIFICATION_DURATION]: IEventVerificationDuration;
  [INSTRUMENTS.EVENT_STARTED_FOLLOWUP]: IEventStartedFollowup;
  [INSTRUMENTS.EVENT_ENDED_FOLLOWUP]: IEventEndedFollowup;
  [INSTRUMENTS.EVENT_FOLLOWUP_DURATION]: IEventFollowupDuration;
  [INSTRUMENTS.EVENT_DISPOSITIONED]: IEventDispositioned;
  [INSTRUMENTS.EVENTS_RETURNED_IN_TAKE]: IEventsReturnedInTake;
  [INSTRUMENTS.AGENT_WEBSOCKET_CONNECTED]: IAgentWebsocketConnected;
  [INSTRUMENTS.AGENT_WEBSOCKET_DISCONNECTED]: IAgentWebsocketDisconnected;
  [INSTRUMENTS.AGENT_DETERRENT_ACTIVATED]: IAgentDeterrentActivated;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE]: IAgent2WayAudioUnmute;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE]: IAgent2WayAudioMute;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS]: IAgent2WayAudioConnectionSuccess;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE]: IAgent2WayAudioConnectionFailure;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_DURATION]: IAgent2WayAudioDuration;
  [INSTRUMENTS.EVENTS_IN_NEW_STATE]: IEventsInNewState;
}

// Collection interfaces that make accessing and documentation easy
// Milestones don't actually get instantiated as OTEL INSTRUMENTS, but `type` of either is required for correct documentation generation
export interface MILESTONE_INTERFACES {
  [INSTRUMENTS.EVENT_RECEIVED]: IEventReceived;
  [INSTRUMENTS.EVENT_IGNORED]: IEventIgnored;
  [INSTRUMENTS.EVENT_AUTO_FILTERED]: IEventAutoFiltered;
  [INSTRUMENTS.EVENT_HELD]: IEventHeld;
  [INSTRUMENTS.EVENT_UNHELD]: IEventUnheld;
  [INSTRUMENTS.EVENT_GOT_FIRST_FRAME]: IEventGotFirstFrame;
  [INSTRUMENTS.EVENT_VIDEO_PROCESSING_ERROR]: IEventVideoProcessingError;
  [INSTRUMENTS.EVENT_READY_TO_SEND_TO_AGENT]: IEventReadyToSendToAgent;
  [INSTRUMENTS.EVENT_EXITED_FEED]: IEventExitedFeed;
  [INSTRUMENTS.EVENT_STARTED_VERIFICATION]: IEventStartedVerification;
  [INSTRUMENTS.EVENT_ENDED_VERIFICATION]: IEventEndedVerification;
  [INSTRUMENTS.EVENT_STARTED_FOLLOWUP]: IEventStartedFollowup;
  [INSTRUMENTS.EVENT_ENDED_FOLLOWUP]: IEventEndedFollowup;
  [INSTRUMENTS.EVENT_DISPOSITIONED]: IEventDispositioned;
  [INSTRUMENTS.AGENT_ENTERED_FEED]: IAgentEnteredFeed;
  [INSTRUMENTS.AGENT_EXITED_FEED]: IAgentExitedFeed;
  [INSTRUMENTS.AGENT_FEED_SLOT_OCCUPANCY_CHANGED]: IAgentFeedSlotOccoupancyChanged;
  [INSTRUMENTS.AGENT_ENTERED_WORK_STATE]: IAgentEnteredWorkState;
  [INSTRUMENTS.AGENT_EXITED_WORK_STATE]: IAgentExitedWorkState;
  [INSTRUMENTS.AGENT_DETERRENT_ACTIVATED]: IAgentDeterrentActivated;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE]: IAgent2WayAudioUnmute;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE]: IAgent2WayAudioMute;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS]: IAgent2WayAudioConnectionSuccess;
  [INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE]: IAgent2WayAudioConnectionFailure;
}
