import { INSTRUMENTS } from 'src/types/measurement';
import { useEffect, useRef } from 'react';
import { useAgentMilestone } from './milestoneHooks';
import { useGetQueueSettings, useGetRepMe } from './repHooks';

export enum AgentExitedFeedReason {
  ACCEPT_NEW_OFF = 'accept_new_off',
  TAKEN_INTO_VR = 'taken_into_vr',
  LOGGED_OUT_ACCEPT_NEW_ON = 'logged_out_accept_new_on',
  TAB_REFRESH_ACCEPT_NEW_ON = 'tab_refresh_accept_new_on',
  TAB_CLOSE_ACCEPT_NEW_ON = 'tab_close_accept_new_on',
  TIMEOUT = 'timeout',
}

type AgentExitedFeedPayload = {
  reason: AgentExitedFeedReason;
  disposition_action?: string;
};

const useAgentExitedFeed = ({
  totalSlots,
  totalAssignedSlots,
  totalUnassignedSlots,
  previousScreen,
}: {
  totalSlots: number;
  totalAssignedSlots: number;
  totalUnassignedSlots: number;
  previousScreen: string;
}) => {
  const sendAgentMetrics = useAgentMilestone();
  const { data: repData } = useGetRepMe();
  const pcs_watch_settings = useGetQueueSettings(repData?.id);

  const handleAgentExited = ({ reason }: AgentExitedFeedPayload) => {
    if (!reason) return;
    sendAgentMetrics({
      agentId: localStorage.getItem('user_id'),
      metricName: INSTRUMENTS.AGENT_EXITED_FEED,
      payload: {
        action_name: reason,
        total_slots: totalSlots || 0,
        total_unassigned_slots: totalUnassignedSlots || 0,
        total_assigned_slots: totalAssignedSlots || 0,
        previous_screen: previousScreen ?? '',
      },
    });
  };

  const handleVisibilityChange = () => {
    if (
      document.visibilityState === 'hidden' &&
      pcs_watch_settings?.data?.acceptNew === true
    ) {
      handleAgentExited({
        reason: AgentExitedFeedReason.TAB_CLOSE_ACCEPT_NEW_ON,
      });
    }
  };

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (pcs_watch_settings?.data?.acceptNew === true) {
      const [navigationEntry] = performance.getEntriesByType(
        'navigation'
      ) as PerformanceNavigationTiming[];
      if (navigationEntry?.type === 'reload') {
        handleAgentExited({
          reason: AgentExitedFeedReason.TAB_REFRESH_ACCEPT_NEW_ON,
        });
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      }
    }
  };
  const handleLogout = () => {
    if (pcs_watch_settings?.data?.acceptNew === true) {
      handleAgentExited({
        reason: AgentExitedFeedReason.LOGGED_OUT_ACCEPT_NEW_ON,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('logout', handleLogout);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('logout', handleLogout);
    };
  }, [pcs_watch_settings?.data?.acceptNew]);

  return {
    handleAgentExited,
  };
};

export default useAgentExitedFeed;
