import {
  MeterProvider,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http';
import { Attributes, Counter, Histogram } from '@opentelemetry/api';

import { METRIC_SOURCE, METRIC_SYSTEM, MetricType, MeasurementType } from 'src/types/measurement';
import { METRIC_INSTRUMENTS } from './metricHooksInstruments';

// Set up OTEL pipeline requirements: collector, exporter, and provider
const collectorOptions = {
  url: process.env.REACT_APP_OTEL_METRIC_COLLECTOR_URL,
  concurrencyLimit: 1,
};
const metricExporter = new OTLPMetricExporter(collectorOptions);
const meterProvider = new MeterProvider({
  readers: [
    new PeriodicExportingMetricReader({
      exporter: metricExporter,
      exportIntervalMillis: 30000,
    }),
  ],
});
const meter = meterProvider.getMeter('pre-crime');

export interface Metric {
  name: string;
  value: number;
  attributes?: Attributes;
}

const prefixMetric = (metricName) => {
  return `pcs_${metricName}`;
}

// Set up an instrument for every type of Metric (not Milestones)
export const counterInstruments: { [key: string]: Counter } =
METRIC_INSTRUMENTS.reduce((accumulator, current) => {
  if (current.types.includes(MeasurementType.METRIC) && current.metricType === MetricType.COUNTER) {
    accumulator[current.name] = meter.createCounter(prefixMetric(current.name), {
      description: current.description,
    });
  }
  return accumulator;
}, {} as { [key: string]: Counter });

export const histogramInstruments: { [key: string]: Histogram } =
METRIC_INSTRUMENTS.reduce((accumulator, current) => {
  if (current.types.includes(MeasurementType.METRIC) && current.metricType === MetricType.COUNTER) {
    accumulator[current.name] = meter.createHistogram(prefixMetric(current.name), {
      description: current.description,
    });
  }
  return accumulator;
}, {} as { [key: string]: Histogram });

export const gaugeInstruments: { [key: string]: Histogram } =
METRIC_INSTRUMENTS.reduce((accumulator, current) => {
  if (current.types.includes(MeasurementType.METRIC) && current.metricType === MetricType.GAUGE) {
    accumulator[current.name] = meter.createHistogram(prefixMetric(current.name), {
      description: current.description,
    });
  }
  return accumulator;
}, {} as { [key: string]: Histogram });

/**
 * React hook to create a function that will increment an OTEL Counter
 * 
 * @returns Function to increment a counter
 */
export const useAddCounter = () => {
  const agentId = localStorage.getItem('id');
  return (metric: Metric) => {
    const counterInstrument = counterInstruments[prefixMetric(metric.name)];
    if (!counterInstrument) {
      console.warn(
        `Counter instrument for metric ${prefixMetric(metric.name)} is not defined.`
      );
      return;
    }
    counterInstrument.add(metric.value, {
      ...metric?.attributes,
      'resource.service.name': [METRIC_SYSTEM, METRIC_SOURCE],
       // Add agentId so we can sum across agents without a new agent resetting the counter
      agentId,
    });
  };
};

/**
 * React hook to create a function that will record the value of an OTEL Histogram
 * 
 * @returns Function to record a histogram measurement
 */
export const useRecordMetric = () => {
  const agentId = localStorage.getItem('id');
  return (metric: Metric) => {
    const histogramInstrument = histogramInstruments[prefixMetric(metric.name)];
    if (!histogramInstrument) {
      console.warn(
        `Histogram instrument for metric ${prefixMetric(metric.name)} is not defined.`
      );
      return;
    }
    histogramInstrument.record(metric.value, {
      ...metric?.attributes,
      'resource.service.name': [METRIC_SYSTEM, METRIC_SOURCE],
      // Add agentId so we can sum across agents without a new agent resetting the measurement
      agentId,
    });
  };
};
