import { useAddCounter } from './metricHooks';
import { INSTRUMENTS } from 'src/types/measurement';
import { useEventMilestone } from './milestoneHooks';

/*
  Any time a scpecialist stopped verifying an event due to any of the following mechanisms:
  * Canceled from V&R (including if the system cancels an event automatically, such as from a disarm or intrusion handle)
  * Moved to FOLLOW_UP from V&R
  * Moved to HOLD status while in V&R
  * Logged out while on V&R
  * Tab refresh while in V&R
  * Tab/window close while in V&R
*/

export enum EventEndedReason {
  CANCELED_FROM_FU = 'canceled_from_fu',
  CANCELED_FROM_VR = 'canceled_from_vr',
  MOVED_TO_FOLLOW_UP = 'moved_to_follow_up',
  MOVED_TO_HOLD = 'moved_to_hold_from_vr',
  LOGGED_OUT = 'logged_out_from_vr',
  TAB_REFRESH = 'tab_refresh_from_vr',
  TAB_CLOSE = 'tab_close_from_vr',
}

type EventEndedVRPayload = {
  reason: EventEndedReason;
  disposition_action?: string;
  cancel_reason?: string;
};

const useEventEndedVerication = ({
  eventId,
  sid,
}: {
  eventId: string;
  sid: string;
}) => {
  const addMetric = useAddCounter();
  const sendEventMetrics = useEventMilestone();

  const handleEventEnded = ({
    reason,
    disposition_action = '',
    cancel_reason = '',
  }: EventEndedVRPayload) => {
    addMetric({
      name: INSTRUMENTS.EVENT_ENDED_VERIFICATION,
      value: 1,
    });

    sendEventMetrics({
      eventId: eventId,
      metricName: INSTRUMENTS.EVENT_ENDED_VERIFICATION,
      payload: {
        location_id: sid,
        agent_id: localStorage.getItem('user_id'),
        reason: reason,
        cancel_reason: cancel_reason,
        disposition_action: disposition_action,
      },
    });
  };

  return {
    handleEventEnded,
  };
};

export default useEventEndedVerication;
