import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useGetPushNotifications } from 'src/hooks/subscriberHooks';
import { getCustomerFormatedDateAndTime } from '../../utils/time';

const NotificationsList = ({ eventId, locationOffset }) => {
  const { data, isLoading } = useGetPushNotifications(eventId);

  if (!eventId) return <Typography>No notification(s) yet.</Typography>;
  if (isLoading) return <LoadingSpinner message="Getting Notifications" />;
  if (!data?.length) return <Typography>No notification(s) yet.</Typography>;

  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Event ID</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((notification) => (
                <TableRow hover key={notification.event_id}>
                  <TableCell>{notification.event_id}</TableCell>
                  <TableCell>{notification.user_id}</TableCell>
                  <TableCell>
                    {getCustomerFormatedDateAndTime(
                      notification.sent_date,
                      locationOffset
                    )}
                  </TableCell>
                  <TableCell>{notification.title}</TableCell>
                  <TableCell>{notification.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

NotificationsList.propTypes = {
  eventId: PropTypes.number,
};
export default NotificationsList;
