import moment from 'moment-timezone';
import type { MomentInput } from 'moment';

export const TIME_FORMAT = 'h:mm:ss A z';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

const usZonesWithOffsets = {
  240: 'America/Puerto_Rico', // Atlantic (Puerto Rico)
  300: 'America/New_York', // Eastern
  360: 'America/Chicago', // Central
  420: 'America/Denver', // Mountain (Except Arizona)
  480: 'America/Los_Angeles', // Pacific
  540: 'America/Anchorage', // Alaskan
  600: 'Pacific/Honolulu', // Hawaii-Aleutian
};

const getZoneByOffset = (offset: number) => {
  return usZonesWithOffsets[Math.abs(offset)] || 'UTC';
};

export const getLocalDateFormatted = ({
  timestampUTC,
  locationOffsetMinutes,
}: {
  timestampUTC: number;
  locationOffsetMinutes: number;
}) => {
  const wrapper = moment.unix(timestampUTC);

  if (locationOffsetMinutes != null) {
    wrapper.tz(getZoneByOffset(locationOffsetMinutes));
  }

  return wrapper.format(DATE_FORMAT);
};

export const getLocalTimeFormatted = ({
  timestampUTC,
  locationOffsetMinutes,
}: {
  timestampUTC: number;
  locationOffsetMinutes: number;
}) => {
  const wrapper = moment.unix(timestampUTC);

  if (locationOffsetMinutes != null) {
    wrapper.tz(getZoneByOffset(locationOffsetMinutes));
  }

  return wrapper.format(TIME_FORMAT);
};

export const getCustomerFormatedDateAndTime = (
  dateTime: MomentInput,
  locationOffsetMinutes?: number,
  format = DATETIME_FORMAT
) => {
  const wrapper =
    typeof dateTime === 'number' ? moment.unix(dateTime) : moment.utc(dateTime);

  if (locationOffsetMinutes != null) {
    wrapper.tz(getZoneByOffset(locationOffsetMinutes));
  }

  return wrapper.format(format);
};
