/** Data Models */
export enum SsCameraConnectionStatus {
  Unknown = 'unknown',
  Offline = 'offline',
  Online = 'online',
}

export enum PcsStatus {
  not_monitored = -50,
  canceled = -40,
  operator_handled = -30,
  pcs_handled = -20,
  expired = -10,
  new = 0,
  watch = 10,
  verify = 20,
  dispatch = 30,
  follow_up = 40,
  hold = 50,
}

export const HandledStatuses = [PcsStatus.operator_handled];

export enum ThreatLevel {
  none = 0,
  motion = 10,
  person = 20,
  multiple = 30,
}

// DISPOSITION
export enum Action {
  system_disarm = 'system_disarm',
  canceled_disarm = 'canceled_disarm',
  canceled_rep = 'canceled_rep',
  canceled_ai = 'canceled_ai',
  canceled_alarm_state = 'canceled_alarm_state',
  canceled_user_app = 'canceled_user_app',
  canceled_safe_word = 'canceled_safe_word',
  canceled_service_time = 'canceled_service_time',
  agent_handled = 'agent_handled',
  dispatched_police = 'dispatched_police',
  dispatched_suspicious_activity = 'dispatched_suspicious_activity',
  dispatched_fire = 'dispatched_fire',
  dispatched_potential_fire = 'dispatched_potential_fire',
  dispatched_medical = 'dispatched_medical',
  canceled_by_customer = 'canceled_by_customer',
  paused_monitoring = 'paused_monitoring',
  no_video = 'no_video',
}
export type Disposition = {
  action?: Action;
  threat_level?: number; // NOTE: this threat_level is different than the ThreatLevel set by PCS
};

export type PcsEvent = {
  eventId: number;
  eventTimestamp?: number;
  userId?: number;
  sid?: number;
  pcs_status?: PcsStatus;
  threat_level?: ThreatLevel;
  alarmState?: string;
  account?: string;
  eventType?: string;
  info?: string;
  video?: Record<string, unknown>;
  uuid?: string;
  handler?: Record<string, unknown>;
  video_path?: string;
  tracks?: [];
  moves?: [];
  faces?: Record<string, unknown>[];
  disposition?: Disposition;
  locationOffset: number;
};

export enum Role {
  ADMIN = 'admin',
  QA = 'qa',
  ENGINEER = 'engineer',
  AGENT_SUPERVISOR = 'agent_supervisor',
  AGENT_OPS = 'agent_ops',
  CUMSOMER = 'customer',
  CX_ORDER_REPORT = 'cx_order_report',
}
