import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Card,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import DataStream from 'src/components/DataStream';
import Page from 'src/components/Page';
import { useVideoPlayer } from 'src/components/VideoPlayer/useVideoPlayer';
import DenseCardHeader from 'src/components/DenseCardHeader';
import { useQueryParams } from 'src/utils/useQueryParams';
import PcsCamera from '../verify-respond/PcsCamera';
import { useMostRecent } from 'src/utils/useMostRecent';
import Compose from 'src/components/mail/Compose';
import VideoActions from '../verify-respond/VideoActions';
import MotionZone from 'src/components/MotionZone';
import AnnotateImage from 'src/components/AnnotateImage';
import { useGetPcsEventQuery } from 'src/hooks/eventHooks';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { Navigate, useNavigate } from 'react-router';
import MetricsModule from 'src/utils/MetricsModule';
import { PushCompose } from 'src/components/pushNotification/PushCompose';
import { Role } from 'src/models';
import { Button } from '@material-ui/core';
import { useGetRepMe } from 'src/hooks/repHooks';
import { useDisarmHook } from 'src/hooks/useDisarmHooks';
import { useSubscription } from 'src/hooks/useSubscription';
import { useSnackbar } from 'notistack';
import { Documentation } from 'src/views/follow-up/Documentation';
import {
  useSetDispositionAction,
  useSetEventDocumentation,
  useSetPcsEventStatus,
} from 'src/hooks/eventHooks';
import {
  MetricsContext,
  createOpenMetricDispatch,
  getEventInQueueDuration,
} from 'src/context/Metrics-context';
import {
  CANCELED_REP,
  selectItemsData as cancelMenuOptions,
} from 'src/components/CancelEventMenu';
import { useSendAutoNotify } from 'src/hooks/subscriberHooks';
import { PcsStatus } from 'src/types';
import { INSTRUMENTS } from 'src/types/measurement';
import { useAddCounter } from 'src/hooks/metricHooks';
import { useEventMilestone } from 'src/hooks/milestoneHooks';

import useElapsedTime from 'src/hooks/useElapsedTime';
import useAgentWorkState, {
  WorkStateAction,
} from 'src/hooks/useAgentWorkState';
import { cancelReasonMappping } from 'src/components/CancelEventMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '100%',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  paper: {
    position: 'absolute',
    width: 700,
    backgroundColor: '#d9d9d9', // matches dispatch modal
    padding: 32,
    fontFamily: theme.typography.fontFamily,
  },
  selectEmpty: {
    color: 'black',
    border: '1px solid black',
  },
}));

const modalStyle = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
};

const FollowUpView = () => {
  const classes = useStyles();
  const { userId, eventId, sid } = useQueryParams();
  const { data: repData } = useGetRepMe();
  const navigate = useNavigate();
  const [_, dispatchMetrics] = useContext(MetricsContext);
  const metrics = new MetricsModule('FollowUp-metric');
  const payload = useRef(metrics.payload({ eventID: eventId }));
  const { enqueueSnackbar } = useSnackbar();
  const [dispositionSubtype, setDispositionType] = useState(null);
  const isAfterFirstLoad = useRef(false);
  const [isDisarmed, setIsDisarmed] = useState(false);
  const [documentation, setDocumentation] = useState('');
  const [isCancelConfirmModalOpen, setIsCancelConfirmModalOpen] =
    useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const pcsStatusFollowUp = 'Follow up';
  const CANCELED_FROM_FU = 'canceled_from_fu';

  const { subscription: realSubscription } = useSubscription({
    userId,
    sid,
  });

  const pushComposeRef = useRef();
  const emailComposeRef = useRef();

  const addCounter = useAddCounter();
  const sendAgentMetrics = useEventMilestone();
  useElapsedTime({ metricName: INSTRUMENTS.EVENT_FOLLOW_UP_DURATION });
  const { setEnteredWorkState } = useAgentWorkState();

  useDisarmHook({
    eventId,
    isDisarmed,
    setIsDisarmed,
    dispatch: dispatchMetrics,
    userId,
    subscription: realSubscription,
    metricPageSource: CANCELED_FROM_FU,
  });

  useEffect(() => {
    localStorage.setItem('previous_screen', 'follow_up_page');
  }, []);

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      // This takes care of sending the follow up end event when the agent logs out or closes window.
      dispatchFollowUpEventEnded(CANCELED_FROM_FU, 'window_closed');
    }
  };

  const dispatchFollowUpEventEnded = (reason = '', cancel_reason = '') => {
    addCounter({ name: INSTRUMENTS.EVENT_ENDED_FOLLOWUP, value: 1 });
    sendAgentMetrics({
      eventId: eventId,
      metricName: INSTRUMENTS.EVENT_ENDED_FOLLOWUP,
      payload: {
        agent_id: localStorage.getItem('user_id'),
        location_id: sid,
        disposition_action: eventQuery?.data?.disposition?.action || '',
        reason,
        cancel_reason,
      },
    });
  };

  const handleLogout = () => {
    dispatchFollowUpEventEnded('logged_out', 'logged_out');
  };

  const handleTabRefresh = () => {
    const [navigationEntry] = performance.getEntriesByType('navigation');
    if (navigationEntry?.type === 'reload') {
      dispatchFollowUpEventEnded('tab_refresh_from_fu', 'tab_refresh_from_fu');
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('logout', handleLogout);
    window.addEventListener('beforeunload', handleTabRefresh);
    addCounter({ name: INSTRUMENTS.EVENT_STARTED_FOLLOWUP, value: 1 });
    sendAgentMetrics({
      eventId: eventId,
      metricName: INSTRUMENTS.EVENT_STARTED_FOLLOWUP,
      payload: {
        agent_id: localStorage.getItem('user_id'),
        location_id: sid,
      },
    });

    payloadMetricsData();
    setEnteredWorkState(WorkStateAction.HANDLE_EVENT_FOLLOW_UP);

    return () => {
      metrics.stopTimer();
      payload.current = metrics.payload({ eventID: eventId });
      dispatchMetrics({ type: 'SEND', payload: payload.current });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('logout', handleLogout);
      window.removeEventListener('beforeunload', handleTabRefresh);
    };
  }, []);

  const payloadMetricsData = () => {
    const watchData = JSON.parse(localStorage.getItem('pcs_watch_settings'));
    watchData
      ? metrics.fetchNewData(
          watchData.acceptNew,
          watchData.pcsStatusThreshold,
          watchData.threatLevel,
          watchData.armstate,
          eventId
        )
      : metrics.fetchNewData();
  };

  const { dispatch, ...playerState } = useVideoPlayer(userId, {
    isPlaying: false,
    volume: 0,
    controls: ['play', 'time', 'progress', 'volume'],
  });
  const [activeItem, setActiveItem] = React.useState();
  const [overRide, setOverRide] = useState(false);
  const [isEmailUntouched, setIsEmailUntouched] = useState(true);

  // Save params -- and redirect to most recent handled event if no params
  useMostRecent();

  const handleItemClick = (item) => {
    setActiveItem({ ...item, rand: Math.random() });
  };

  const [importantTracks, setImportantTracks] = React.useState([]);
  const handleImportant = (img_file) => {
    setImportantTracks([img_file]);
  };

  // MOTION ZONE or ANNOTATE
  const [videoAction, setVideoAction] = React.useState(null);
  const handleVideoAction = (actionId) => {
    setVideoAction(actionId);
    if (actionId === 'motion_zone') mzRef.current.capture();
    if (actionId === 'annotate') annotateRef.current.capture();
  };
  const playerElRef = React.useRef();
  const mzRef = React.useRef();
  const annotateRef = React.useRef();
  const handleReady = (playerEl) => {
    playerElRef.current = playerEl.current;
  };
  const pts_seconds = React.useRef(null);
  const handleProgress = (time) => {
    pts_seconds.current = time;
  };
  const handleZoneDone = () => {
    if (videoAction === 'motion_zone')
      dispatch({ type: 'FORCE_MOTION_ZONE_OVERLAY' });
    setVideoAction(null);
  };

  const eventQuery = useGetPcsEventQuery(eventId);
  const setDocumentationMutation = useSetEventDocumentation(eventId);
  const setActionMutation = useSetDispositionAction(eventId);
  const pcsStatusMutation = useSetPcsEventStatus();
  const openMetricDispatch = createOpenMetricDispatch(dispatchMetrics);
  const autoNotification = useSendAutoNotify(eventId, userId);
  // Disable complete button unless:
  // - documentation has been written
  // - an email template has been selected (this always exists because it has a default) (this drives/determines the disposition reason)
  // - a disposition reason has been added to the event (happens automatically when an email template is selected)
  const isCompleteButtonEnabled = () => {
    return (
      documentation.length > 0 &&
      dispositionSubtype &&
      !eventQuery.data?.disposition?.action
    );
  };

  useEffect(() => {
    if (eventQuery.data == null) {
      return;
    }

    if (
      isAfterFirstLoad.current &&
      eventQuery?.data?.disposition?.action != null
    ) {
      dispatchMetrics({
        type: 'SEND',
        payload: {
          metricName: 'event-already-handled',
          payload: { eventId, agentID: repData?.user_id },
        },
      });
      enqueueSnackbar(
        `The event has been already handled [${eventQuery?.data?.disposition?.action}]`,
        {
          variant: 'info',
        }
      );
      navigate('/app/queue');
    }
    isAfterFirstLoad.current = true;
  }, [eventQuery.data]);

  // Open a confirmation modal when the user first clicks to cancel the event
  const handleCancelEventClick = () => {
    // metric for event_ended_followup
    dispatchFollowUpEventEnded(CANCELED_FROM_FU, 'canceled');
    setIsCancelConfirmModalOpen(true);
  };

  // Close the "cancel event" confirmation modal with no action
  const handleCloseCancelConfirmModal = () => {
    setCancelReason('');
    setIsCancelConfirmModalOpen(false);
    // Metric to Grafana
    addCounter({ name: INSTRUMENTS.EVENT_ENDED_VERIFICATION, value: 1 });
    // metric for event_ended_followup
    dispatchFollowUpEventEnded(CANCELED_FROM_FU, 'canceled');
  };

  // User confirms they do want to cancel the event
  const handleConfirmCancelEvent = () => {
    setIsCancelConfirmModalOpen(false);

    // metric for event_ended_followup
    dispatchFollowUpEventEnded(CANCELED_FROM_FU, 'canceled');

    // metric for cancelling from follow-up page
    openMetricDispatch({
      metricName: 'event-canceled',
      payload: {
        eventId,
        reasonCancelEvent: cancelReason,
        PCSstatus: pcsStatusFollowUp,
        reason: 'canceled',
      },
    });

    // mark cancelled
    pcsStatusMutation.mutate({
      eventId,
      pcsStatus: PcsStatus.canceled,
      disposition_reason: cancelReason,
      disposition_action: CANCELED_REP,
    });

    // send notification
    if (cancelReason === 'Duplicate Event') {
      // no action
    } else if (cancelReasonMappping[cancelReason] === '') {
      autoNotification.send('common_event');
    } else {
      autoNotification.send(cancelReasonMappping[cancelReason]);
    }

    // send the agent back to queue
    navigate('/app/queue');
  };

  // Things that need to happen when handling the event is complete
  const markComplete = () => {
    metrics.fetchNewData();

    // Save Specialist's Documentation
    setDocumentationMutation.mutate({ documentation });

    openMetricDispatch({
      metricName: 'event-documentation-set',
      payload: {
        eventId,
        documentation,
        PCSStatus: pcsStatusFollowUp,
      },
    });

    // Set Disposition
    const agentHandledDisposition = 'agent_handled'; // This is the only disposition used from FollowUp page
    localStorage.setItem('disposition', agentHandledDisposition);
    setActionMutation.mutate({ action: agentHandledDisposition });
    openMetricDispatch({
      metricName: 'Disposition-changed',
      payload: {
        eventId,
        disposition: agentHandledDisposition,
        PCSStatus: pcsStatusFollowUp,
        reason: 'completed',
      },
    });

    // Complete/Finalize the event by updating the status
    pcsStatusMutation.mutate({
      eventId,
      pcsStatus: -30,
    });

    // Follow Up Complete Metric
    openMetricDispatch({
      metricName: 'follow-up-complete-notification',
      payload: {
        eventId,
        PCSStatus: pcsStatusFollowUp,
      },
    });

    // Follow Up Handle Time Metric
    if (eventId) {
      openMetricDispatch({
        metricName: 'event-handle-time',
        payload: {
          eventId,
          duration: getEventInQueueDuration(eventId),
          PCSstatus: pcsStatusFollowUp,
        },
      });
    }
    // Follow Up Ended Metric
    dispatchFollowUpEventEnded(CANCELED_FROM_FU, 'canceled');

    // Send customer notifications
    pushComposeRef.current.send();
    emailComposeRef.current.send();

    // Redirect to Queue once handling the event is completed
    navigate('/app/queue');
  };

  // Return special cases for some event statuses
  if (!eventId) return null;

  if (isDisarmed) {
    return (
      <ConfirmDialog
        message="Customer disarmed system, event has ended"
        cancelButtonText="RETURN TO QUEUE"
        continueButtonText={null}
        onCancel={() => {
          dispatchFollowUpEventEnded(CANCELED_FROM_FU, 'canceled');
          navigate('/app/queue');
        }}
      />
    );
  }

  if (repData?.role === Role.ENGINEER || repData?.role === Role.QA)
    return <Navigate to="/app/dashboard" />;

  return (
    <Page title="Follow Up">
      <Container className={classes.root} maxWidth={false}>
        <Grid container spacing={1}>
          {/* LEFT - VIDEO PLAYER */}
          <Grid item xs={6}>
            <Card>
              <Box height="50vh" position="relative">
                <PcsCamera // prettier-ignore
                  eventId={eventId}
                  playerState={{ ...playerState }}
                  activeItem={activeItem}
                  hide={!!videoAction}
                  onReady={handleReady}
                  onProgress={handleProgress}
                  liveIndicator={false}
                  eventQuery={eventQuery}
                  view={'follow-up'}
                />
                <VideoActions onClickAction={handleVideoAction} />
                <MotionZone
                  mzRef={mzRef}
                  playerElRef={playerElRef}
                  onDone={handleZoneDone}
                />
                <AnnotateImage
                  eventId={eventId}
                  mzRef={annotateRef}
                  playerElRef={playerElRef}
                  pts_seconds={pts_seconds.current}
                  onDone={handleZoneDone}
                />
              </Box>
            </Card>
          </Grid>

          {/* RIGHT - DATASTREAM */}
          <Grid item xs={6}>
            <Card>
              <Box height="50vh">
                <DenseCardHeader title="Datastream" />
                <Box height="100%" p={1} overflow="hidden">
                  {!eventId && <Typography>No event</Typography>}
                  {eventId && (
                    <DataStream
                      eventId={eventId}
                      onItemClick={handleItemClick}
                      isSelectingPeople={true}
                      onImportant={handleImportant}
                      showCluster
                      eventQuery={eventQuery}
                    />
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>

          {/* LEFT - EMAIL TEMPLATES */}
          <Grid item xs={6}>
            <Card>
              <DenseCardHeader title="Email Message" />
              <Box height="90%">
                <Compose
                  ref={emailComposeRef}
                  setIsEmailUntouched={setIsEmailUntouched}
                  importantTracks={importantTracks}
                  eventQuery={eventQuery}
                  dispositionSubtype={dispositionSubtype}
                  setDispositionType={setDispositionType}
                />
              </Box>
            </Card>
          </Grid>

          {/* CENTER - PUSH NOTIFICATION */}
          <Grid item xs={3}>
            <Card style={{ height: '100%' }}>
              <DenseCardHeader title="Push Notification" />
              <Box height="100%" marginTop="3px">
                <PushCompose
                  ref={pushComposeRef}
                  eventId={eventId}
                  eventQuery={eventQuery}
                  dispositionSubtype={dispositionSubtype}
                />
              </Box>
            </Card>
          </Grid>

          {/* RIGHT - DOCUMENTATION */}
          <Grid item xs={3}>
            <Card
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <DenseCardHeader title="Event Documentation" />
                <Box marginTop="3px">
                  <Documentation
                    documentation={documentation}
                    setDocumentation={setDocumentation}
                  />
                </Box>
              </div>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
              >
                <Button
                  variant="outlined"
                  color="default"
                  onClick={handleCancelEventClick}
                >
                  Cancel Event
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => markComplete()}
                  disabled={!isCompleteButtonEnabled()}
                >
                  Complete
                </Button>
              </Box>
            </Card>
          </Grid>
          <Modal open={isCancelConfirmModalOpen}>
            <div style={modalStyle} className={classes.paper}>
              <Box>
                Events should normally be canceled from the Queue or
                Verify+Respond pages.
              </Box>
              <Box sx={{ marginTop: '40px' }}>
                Selecting a cancel reason will override the disposition
                template. Are you sure you want to cancel this event?
              </Box>
              <Box
                sx={{ marginTop: '40px', marginLeft: '180px' }}
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
              >
                <Box sx={{ marginTop: '8px', marginRight: '20px' }}>
                  Cancel Reason:{' '}
                </Box>
                <Select
                  label="Cancel Reason"
                  variant="outlined"
                  id="follow-up-cancel-reason-select"
                  value={cancelReason}
                  onChange={(e) => setCancelReason(e.target.value)}
                  className={classes.selectEmpty}
                >
                  {cancelMenuOptions.map((option) => {
                    return (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box
                sx={{ marginTop: '40px' }}
                display="flex"
                justifyContent="space-around"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseCancelConfirmModal}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!cancelReason}
                  onClick={handleConfirmCancelEvent}
                >
                  Cancel Event
                </Button>
              </Box>
            </div>
          </Modal>
        </Grid>
      </Container>
    </Page>
  );
};

export default FollowUpView;
