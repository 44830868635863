import { CAMERA_MODELS, outdoorCameraModels } from 'src/constants';
import { Action } from 'src/models';
import { NotificationCategory } from 'src/types';

export const getPCSstatusByLocation = () =>
  location.pathname.includes('audit-list')
    ? 'Audit List'
    : location.pathname.includes('queue')
    ? 'Queue'
    : location.pathname.includes('follow-up')
    ? 'Follow up'
    : location.pathname.includes('verify-respond')
    ? 'verifying'
    : 'New';

export const isIterable = (obj) => {
  if (!obj) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
};

export const getUnixEpoch = () => Math.floor(Date.now() / 1000);

export const playTone = (path: string): void => {
  let audioTone: HTMLMediaElement = document.querySelector('#queueTone');
  if (!audioTone) {
    const root = document.querySelector('#root');
    audioTone = Object.assign(document.createElement('audio'), {
      id: 'queueTone',
      src: path,
      type: 'audio/mpeg',
    });
    root.appendChild(audioTone);
  }
  audioTone.play();
};

export const getCameraName = (info: string): string => {
  return info && info.includes('"') ? info.split('"')[1] : info;
};

export const isOutdoorModel = (model: CAMERA_MODELS) =>
  outdoorCameraModels.includes(model);

export const getNotificationCategoryFromDispositionTypes = (
  dispositionType: string
) => {
  const personEvents = [
    'familiar_person_on_property',
    'unfamiliar_person_on_property_delivery',
    'unfamiliar_person_on_property_entry',
    'unfamiliar_person_on_property_outdoor_service',
    'outdoor_service_identified_person',
    'uniformed_authorities',
    'unattended_minor',
    'unknown_person_out_of_range',
    'non_threatening_deterrence',
    'unknown_person_no_response',
    'unknown_person_camera_failure',
    'car_on_property',
    'unknown_person_other',
    'unfamiliar_person_on_property_escalation',
    'interior_camera_placement',
  ];

  const emergencyEvents = [
    'emergency_events',
    'urgent_events',
    Action.dispatched_police,
    Action.dispatched_fire,
    Action.dispatched_medical,
    Action.dispatched_potential_fire,
    Action.dispatched_suspicious_activity,
  ];

  if (personEvents.includes(dispositionType)) {
    return NotificationCategory.person_events;
  }

  if (emergencyEvents.includes(dispositionType)) {
    return NotificationCategory.emergency_events;
  }

  return NotificationCategory.common_events;
};

/**
 * Returns true if dispositionType is critical event, false otherwise
 * @param dispositionType
 * @returns boolean indicating if dispositionType is critical event
 */
export const getNotificationCriticalityFromDispositionTypes = (
  dispositionType: string
) => {
  const criticalEvents = [
    'urgent_events',
    'emergency_events',
    'unfamiliar_person_on_property_escalation',
    Action.dispatched_police,
    Action.dispatched_fire,
    Action.dispatched_medical,
    Action.dispatched_potential_fire,
    Action.dispatched_suspicious_activity,
  ];

  if (criticalEvents.includes(dispositionType)) {
    return true;
  }

  return false;
};
