import { v4 as uuidv4 } from 'uuid';
import { addCameraInfo } from '../../../context/CameraInfo';
import moment from 'moment-timezone';

export class PcsSession {
  id = uuidv4();
  start_date = Date.now();
  stream_attempt_start: number = null;
  stream_start: number = null;
  livestreamAttempts = 0;
  livestreamSuccessfulAttempts = 0;
  livestreamPauses = 0;
  livestreamStops = 0;
  livestreamFreezeDetections = 0;
  ttls = [];

  audioStats: Array<{ property: string; value: string }> = [];
  audioStatsObject = {};
  videoStats: Array<{ property: string; value: string }> = [];
  videoStatsObject = {};

  constructor(
    private _cameraUuid: string,
    private _sid: number,
    private _eventId: number | null
  ) {}

  updateStats(audioStats, videoStats) {
    this.audioStatsObject = audioStats || {};
    this.videoStatsObject = videoStats || {};
    this.audioStats = Object.entries(audioStats || {}).map(
      ([property, value]) => ({
        property,
        value: String(value),
      })
    );
    this.videoStats = Object.entries(videoStats || {}).map(
      ([property, value]) => ({
        property,
        value: String(value),
      })
    );
  }

  livestreamAttempt() {
    this.livestreamAttempts++;
    this.stream_attempt_start = Date.now();
    addCameraInfo({
      cameraUuid: this._cameraUuid,
      sid: this._sid,
      eventId: this._eventId,
      session_id: this.id,
      start_date: moment(this.start_date).toISOString(),
      stream_start: null,
      stream_attempt_start: moment(this.stream_attempt_start).toISOString(),
      livestreamAttempts: 1,
      livestreamSuccessfulAttempts: 0,
      livestreamPauses: 0,
      livestreamStops: 0,
      livestreamFreezeDetections: 0,
      ttl: 0,
      operation: 'livestreamAttempt',
    });
  }

  livestreamSuccessfulAttempt() {
    this.livestreamSuccessfulAttempts++;
    this.stream_start = Date.now();
    this.ttls.push((this.stream_start - this.stream_attempt_start) / 1000);

    addCameraInfo({
      cameraUuid: this._cameraUuid,
      sid: this._sid,
      eventId: this._eventId,
      session_id: this.id,
      start_date: moment(this.start_date).toISOString(),
      stream_start: moment(this.stream_start).toISOString(),
      stream_attempt_start: moment(this.stream_attempt_start).toISOString(),
      livestreamAttempts: 0,
      livestreamSuccessfulAttempts: 1,
      livestreamPauses: 0,
      livestreamStops: 0,
      livestreamFreezeDetections: 0,
      ttl: (this.stream_start - this.stream_attempt_start) / 1000,
      operation: 'livestreamSuccessfulAttempt',
      extra: {
        video: this.videoStatsObject,
        audio: this.audioStatsObject,
      },
    });
  }

  livestreamPause() {
    this.livestreamPauses++;
    addCameraInfo({
      cameraUuid: this._cameraUuid,
      sid: this._sid,
      eventId: this._eventId,
      session_id: this.id,
      start_date: moment(this.start_date).toISOString(),
      stream_start: moment(this.stream_start).toISOString(),
      stream_attempt_start: moment(this.stream_attempt_start).toISOString(),
      livestreamAttempts: 0,
      livestreamSuccessfulAttempts: 0,
      livestreamPauses: 1,
      livestreamStops: 0,
      livestreamFreezeDetections: 0,
      ttl: 0,
      operation: 'livestreamPause',
      extra: {
        video: this.videoStatsObject,
        audio: this.audioStatsObject,
      },
    });
  }

  livestreamStop() {
    this.livestreamStops++;
    addCameraInfo({
      cameraUuid: this._cameraUuid,
      sid: this._sid,
      eventId: this._eventId,
      session_id: this.id,
      start_date: moment(this.start_date).toISOString(),
      stream_start: moment(this.stream_start).toISOString(),
      stream_attempt_start: moment(this.stream_attempt_start).toISOString(),
      livestreamAttempts: 0,
      livestreamSuccessfulAttempts: 0,
      livestreamPauses: 0,
      livestreamStops: 1,
      livestreamFreezeDetections: 0,
      ttl: 0,
      operation: 'livestreamStop',
      extra: {
        video: this.videoStatsObject,
        audio: this.audioStatsObject,
      },
    });
  }

  livestreamFreezeDetection() {
    this.livestreamFreezeDetections++;
    addCameraInfo({
      cameraUuid: this._cameraUuid,
      sid: this._sid,
      eventId: this._eventId,
      session_id: this.id,
      start_date: moment(this.start_date).toISOString(),
      stream_start: moment(this.stream_start).toISOString(),
      stream_attempt_start: moment(this.stream_attempt_start).toISOString(),
      livestreamAttempts: 0,
      livestreamSuccessfulAttempts: 0,
      livestreamPauses: 0,
      livestreamStops: 0,
      livestreamFreezeDetections: 1,
      ttl: 0,
      operation: 'livestreamFreezeDetection',
      extra: {
        video: this.videoStatsObject,
        audio: this.audioStatsObject,
      },
    });
  }

  getCurrentStreamDuration() {
    return this.stream_start ? Date.now() - this.stream_start : 0;
  }

  getSessionDuration() {
    return Date.now() - this.start_date;
  }

  getTTLs() {
    return this.ttls.reduce((a, b) => a + b, 0);
  }

  getAvgTTLs() {
    return this.ttls.length ? this.getTTLs() / this.ttls.length : 0;
  }
}
