import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import WebIcon from '@material-ui/icons/Web';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { useListPcsEventsQuery } from 'src/hooks/eventHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { formatHandlerFromEmail, mapPcsEventStatus } from 'src/utils/format';
import { Clipboard } from 'react-feather';
import { Navigate } from 'react-router';
import { Role } from 'src/models';
import { useGetRepMe } from 'src/hooks/repHooks';
import { getCustomerFormatedDateAndTime } from 'src/utils/time';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ className, filter, searchValue, filterValues }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { data: repData } = useGetRepMe();

  const params = {
    skip: page * limit,
    limit: limit,
    pcs_status: filterValues.pcs_status,
    handling: filterValues.handling,
    sid: filterValues.sid,
    user_id: filterValues.user_id,
    detection_filter: filterValues.detection_filter,
    eventId: filterValues.eventId,
    endDatetime:
      filterValues.endDatetime == undefined ? '' : filterValues.endDatetime.ts,
    startDatetime:
      filterValues.startDatetime == undefined
        ? ''
        : filterValues.startDatetime.ts,
    searchBy: searchValue,
  };

  if (filter === 'handling') {
    params['handling'] = true;
  }
  if (filter === 'late') {
    params['handling'] = false;
    params['pcs_status'] = 0;
    params['until'] = 0;
  }
  if (filter === 'expired') {
    params['pcs_status'] = -10;
  }
  for (let key of Object.keys(params)) {
    if (params[key] === '') {
      delete params[key];
    }
  }

  const eventsQuery = useListPcsEventsQuery(params);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = eventsQuery.data.map(
        (customer) => customer.eventId
      );
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const tableCellsData = [
    { id: 1, value: 'Go' },
    { id: 2, value: 'Time' },
    { id: 3, value: 'Account' },
    { id: 4, value: 'Event ID' },
    { id: 5, value: 'UID' },
    { id: 6, value: 'Event' },
    { id: 7, value: 'Alarm Status' },
    { id: 8, value: 'Videos' },
    { id: 9, value: 'Tracks' },
    { id: 10, value: 'Motion' },
    { id: 11, value: 'Faces' },
    { id: 12, value: 'Status' },
    { id: 13, value: 'Threat' },
    { id: 14, value: 'Handler' },
  ];

  const tableCells = tableCellsData.map((cell) => (
    <TableCell key={cell.id}> {cell.value}</TableCell>
  ));

  if (eventsQuery.isLoading) return <LoadingSpinner />;
  if (repData?.role === Role.AGENT_OPS) return <Navigate to="/app/dashboard" />;

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selectedCustomerIds.length !== 0 &&
                      selectedCustomerIds.length === eventsQuery.data.length
                    }
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < eventsQuery.data.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {tableCells}
              </TableRow>
            </TableHead>
            <TableBody>
              {eventsQuery.data?.slice(0, limit).map((event) => (
                <TableRow
                  hover
                  key={event.eventId}
                  selected={selectedCustomerIds.indexOf(event.eventId) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedCustomerIds.indexOf(event.eventId) !== -1
                      }
                      onChange={(e) => handleSelectOne(e, event.eventId)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    {(repData?.role === Role.ADMIN ||
                      repData?.role === Role.AGENT_OPS ||
                      repData?.role === Role.AGENT_SUPERVISOR) && (
                      <Link
                        to={`/app/verify-respond?userId=${event.userId}&sid=${event.sid}&eventId=${event.eventId}&uuid=${event.uuid}`}
                      >
                        <IconButton aria-label="verify-respond">
                          <WebIcon />
                        </IconButton>
                      </Link>
                    )}
                    {(repData?.role === Role.ADMIN ||
                      repData?.role === Role.AGENT_SUPERVISOR) && (
                      <Link
                        to={`/app/follow-up?userId=${event.userId}&sid=${event.sid}&eventId=${event.eventId}&uuid=${event.uuid}`}
                      >
                        <IconButton aria-label="verify-respond">
                          <CreateIcon />
                        </IconButton>
                      </Link>
                    )}
                    {(repData?.role === Role.ADMIN ||
                      repData?.role === Role.ENGINEER ||
                      repData?.role === Role.AGENT_SUPERVISOR ||
                      repData?.role === Role.QA) && (
                      <Link
                        to={`/app/audit-list?userId=${event.userId}&sid=${event.sid}&eventId=${event.eventId}&uuid=${event.uuid}`}
                      >
                        <IconButton aria-label="audit-list">
                          <Clipboard />
                        </IconButton>
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>
                    {getCustomerFormatedDateAndTime(
                      event.eventTimestamp,
                      event.locationOffset
                    )}
                  </TableCell>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        className={'fs-exclude'}
                      >
                        {event.account}
                        <br /> {event.sid}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={'fs-exclude'}>
                    {event.eventId}
                  </TableCell>
                  <TableCell className={'fs-exclude'}>{event.userId}</TableCell>
                  <TableCell>{event.info}</TableCell>
                  <TableCell>{event.alarmState}</TableCell>
                  <TableCell>
                    {Object.keys(event.video).length > 0
                      ? Object.keys(event.video).length
                      : ''}
                  </TableCell>
                  <TableCell>{event.tracks && event.tracks.length}</TableCell>
                  <TableCell>{event.moves && event.moves.length}</TableCell>
                  <TableCell>{event.faces && event.faces.length}</TableCell>
                  <TableCell>{mapPcsEventStatus(event)}</TableCell>
                  <TableCell>{event.threat_level}</TableCell>
                  <TableCell>
                    {formatHandlerFromEmail(event.handler?.email)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={-1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 100, 200]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.string,
};

export default Results;
