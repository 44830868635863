import {
  Box,
  MenuItem,
  Button,
  Divider,
  IconButton,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Select,
  Paper,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Maximize as MaximizeIcon,
  Minimize as MinimizeIcon,
  X as XIcon,
} from 'react-feather';
import QuillEditor from 'src/components/mail/QuillEditor';
import { usePcsClient } from 'src/context/pcs-auth-context';
import { useSendFollowUpEmail } from 'src/hooks/subscriberHooks';
import { formatEmail, htmlToText } from 'src/utils/format';
import { useQueryParams } from 'src/utils/useQueryParams';
import { useGetRepMe } from 'src/hooks/repHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import MetricsModule from 'src/utils/MetricsModule';
import { MetricsContext } from 'src/context/Metrics-context';
import { useSubscription } from '../../hooks/useSubscription';
import { getMailTemplate } from './MailTemplates';
import {
  getCameraName,
  getNotificationCategoryFromDispositionTypes,
  getNotificationCriticalityFromDispositionTypes,
} from 'src/utils/utils';
import { getLocalDateFormatted, getLocalTimeFormatted } from 'src/utils/time';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
    // maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
    // width: 600,
    // position: 'fixed',
    // bottom: 0,
    // right: 0,
    margin: theme.spacing(0.5),
    outline: 'none',
    zIndex: 2000,
    display: 'flex',
    flexDirection: 'column',
    // minHeight: 500
  },
  fullScreen: {
    height: '100%',
    width: '100%',
    maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
    position: 'fixed',
    bottom: 0,
    right: 0,
    '& .ql-container .ql-editor': {
      // minHeight: 290,
      height: '80vh',
    },
  },
  input: {
    height: '90%',
    width: '100%',
  },
  editor: {
    flexGrow: 1,
    '& .ql-editor': {
      // minHeight: 320,
      height: '25.5vh',
    },
  },
  action: {
    marginRight: theme.spacing(1),
  },
  dispositionTemplate: {
    width: 400,
    marginRight: 12,
  },
  subject: {
    marginRight: 5,
    marginLeft: 12,
  },
  emailHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Compose = (
  {
    importantTracks,
    eventQuery,
    dispositionSubtype,
    setDispositionType,
    setIsEmailUntouched = () => null,
  },
  ref
) => {
  const client = usePcsClient();
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const { isComposeOpen } = useSelector(state => state.mail);
  const [fullScreen, setFullScreen] = useState(false);
  const { userId, eventId, uuid, sid } = useQueryParams();
  const [_, dispatchMetrics] = useContext(MetricsContext);
  const metrics = new MetricsModule('send-email');
  const payload = useRef(metrics.payload({ eventID: eventId }));
  const prevMessage = useRef('');

  const { subscription } = useSubscription({
    userId,
    sid,
  });

  const [inputValue, setInputValue] = useState('');

  const event = eventQuery.data;
  const repInfo = useGetRepMe();
  const editedRef = React.useRef(false);
  const [messageBody, setMessageBody] = useState('');
  const messageBodyRef = React.useRef();
  messageBodyRef.current = messageBody;
  const sendFollowUpEmail = useSendFollowUpEmail();
  const { enqueueSnackbar } = useSnackbar();

  const payloadMetricsData = () => {
    const watchData = JSON.parse(localStorage.getItem('pcs_watch_settings'));
    watchData
      ? metrics.fetchNewData(
          watchData.acceptNew,
          watchData.pcsStatusThreshold,
          watchData.threatLevel,
          watchData.armstate,
          eventId
        )
      : null;
  };

  // Only Update until a message body has been set successfully
  React.useEffect(() => {
    payloadMetricsData();
    if (
      event &&
      !editedRef.current &&
      repInfo.data &&
      messageBody === '' &&
      dispositionSubtype != null
    ) {
      const mailFormatter = getMailTemplate({
        camera: getCameraName(event?.info),
        agent: repInfo.data.firstName,
        date: getLocalDateFormatted({
          timestampUTC: event?.eventTimestamp,
          locationOffsetMinutes: event?.locationOffset,
        }),
        time: getLocalTimeFormatted({
          timestampUTC: event?.eventTimestamp,
          locationOffsetMinutes: event?.locationOffset,
        }),
        dispositionSubtype: dispositionSubtype || 'default',
      });
      const [message, subject] =
        typeof mailFormatter === 'object'
          ? [mailFormatter.getMessage(), mailFormatter.getSubject()]
          : [
              `An email template for the disposition '${dispositionSubtype}' has not been created yet.`,
              'Monitoring Summary',
            ];
      setMessageBody(message);
      setInputValue(subject);
      prevMessage.current = message;
    }
  }, [event, repInfo.isSuccess, editedRef.current]);

  // Only Update on Disposition Subtype Change after initial set
  React.useEffect(() => {
    payloadMetricsData();
    if (event && repInfo.data) {
      const mailFormatter = getMailTemplate({
        camera: getCameraName(event?.info),
        agent: repInfo.data.firstName,
        date: getLocalDateFormatted({
          timestampUTC: event?.eventTimestamp,
          locationOffsetMinutes: event?.locationOffset,
        }),
        time: getLocalTimeFormatted({
          timestampUTC: event?.eventTimestamp,
          locationOffsetMinutes: event?.locationOffset,
        }),
        dispositionSubtype: dispositionSubtype || 'default',
      });
      const [message, subject] =
        typeof mailFormatter === 'object'
          ? [mailFormatter.getMessage(), mailFormatter.getSubject()]
          : [
              `An email template for the disposition '${dispositionSubtype}' has not been created yet.`,
              'Monitoring Summary',
            ];
      setMessageBody(message);
      setInputValue(subject);
      prevMessage.current = message;
    }
  }, [dispositionSubtype]);

  React.useEffect(() => {
    let newMessage = messageBodyRef.current;
    if (importantTracks.length > 0) {
      importantTracks.forEach((img_file) => {
        newMessage = `${newMessage}<br/><br/><img src='${process.env.REACT_APP_PCS_ADMIN_URL}/${img_file}'>`;
      });
      setMessageBody(newMessage);
      editedRef.current = true;
    }
    return () => {};
  }, [importantTracks]);

  const handleChange = (value) => {
    setMessageBody(value);
  };

  const onSendBtnClickHandler = useCallback(
    async (clickEvent) => {
      if (clickEvent != null) {
        clickEvent.stopPropagation();
      }
      setIsEmailUntouched(true);
      const emailResponse = await sendFollowUpEmail.mutateAsync({
        uid: userId,
        subject: inputValue,
        // html: formatEmail(messageBody),
        html: messageBody,
        text: htmlToText(messageBody),
        eventId: eventId,
        category:
          getNotificationCategoryFromDispositionTypes(dispositionSubtype),
        critical:
          getNotificationCriticalityFromDispositionTypes(dispositionSubtype),
      });

      let message;
      let variant;

      if (emailResponse.is_duplicated) {
        message = 'Email already sent to this user for this event';
      } else if (emailResponse.is_skipped) {
        message = 'User has opted out of email notifications';
      } else if (emailResponse.delivered) {
        message = 'Email sent successfully';
        variant = 'success';
      }

      enqueueSnackbar(message, { variant });

      payload.current = metrics.payload({
        eventID: eventId,
        agentID: localStorage.getItem('id') || null,
        cameraUUID: uuid,
      });
      dispatchMetrics({
        type: 'SEND',
        payload: {
          ...payload.current,
          disposition: dispositionSubtype,
        },
        metricName: 'follow-up-send-email',
        eventID: eventId,
      });
      if (
        prevMessage.current.replace(/ /g, '') !== messageBody.replace(/ /g, '')
      ) {
        payload.current = metrics.payload({
          eventID: eventId,
          agentID: localStorage.getItem('id') || null,
          cameraUUID: uuid,
        });
        dispatchMetrics({
          type: 'SEND',
          payload: {
            ...payload.current,
            metricName: 'follow-up-send-email-changed',
            eventID: eventId,
            disposition: dispositionSubtype,
          },
        });
        editedRef.current = false;
        prevMessage.current = messageBody;
      }
    },
    [userId, inputValue, messageBody, eventId, metrics]
  );

  useImperativeHandle(ref, () => ({
    send: onSendBtnClickHandler,
  }));

  const handleExitFullScreen = () => {
    setFullScreen(false);
  };

  const handleEnterFullScreen = () => {
    setFullScreen(true);
  };

  const handleClose = () => {
    // dispatch(closeCompose());
  };

  if (!repInfo.isSuccess && !subscription)
    return <LoadingSpinner message="Getting rep name" />;

  if (sendFollowUpEmail.isSuccess) {
    return (
      <Box m={2}>
        <Typography gutterBottom>Email sent to customer</Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.action}
          onClick={sendFollowUpEmail.reset}
        >
          Compose another email
        </Button>
      </Box>
    );
  }

  return (
    <Paper
      className={clsx(classes.root, { [classes.fullScreen]: fullScreen })}
      elevation={12}
      onClick={() => setIsEmailUntouched(false)}
    >
      <Box>
        <FormControl variant="outlined" className={classes.dispositionTemplate}>
          <InputLabel>Disposition Template</InputLabel>
          <Select
            value={dispositionSubtype}
            label="Disposition template"
            name="pcs_status"
            onChange={({ target }) => setDispositionType(target.value)}
          >
            <MenuItem value="unfamiliar_person_on_property_delivery">
              Delivery
            </MenuItem>
            <MenuItem value="familiar_person_on_property">
              Profile Match
            </MenuItem>
            <MenuItem value="unfamiliar_person_on_property_outdoor_service">
              Outdoor Service - Unidentified Person
            </MenuItem>
            <MenuItem value="outdoor_service_identified_person">
              Outdoor Service - Identified Person
            </MenuItem>
            <MenuItem value="unfamiliar_person_on_property_entry">
              Unfamiliar Person - Engaged
            </MenuItem>
            <MenuItem value="unattended_minor">
              Unattended Minor - No Profile
            </MenuItem>
            <MenuItem value="uniformed_authorities">
              Uniformed Authorities
            </MenuItem>
            <MenuItem value="non_threatening_deterrence">
              Non-Threatening Deterrence
            </MenuItem>
            <MenuItem value="interior_camera_placement">
              Interior Camera Placement
            </MenuItem>
            <MenuItem value="unknown_person_out_of_range">
              Unknown Person - Out of Range
            </MenuItem>
            <MenuItem value="unknown_person_no_response">
              Unknown P.O.P - Engaged, Did Not Respond
            </MenuItem>
            <MenuItem value="unknown_person_camera_failure">
              Unknown P.O.P - Cameras Not Loading
            </MenuItem>
            <MenuItem value="unknown_person_other">
              Unknown P.O.P - Other
            </MenuItem>
            <MenuItem value="car_on_property">
              Car Arrives on Property - No P.O.P
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <Box className={classes.emailHeader}>
        <Box className={classes.input} display="flex">
          {/* <Input className={classes.input} disableUnderline placeholder="To" /> */}
          <Typography color="textSecondary" className={classes.subject}>
            Subject:
          </Typography>
          <Input
            className={classes.input}
            disableUnderline
            placeholder="Subject"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Box>
      </Box>
      <Divider />
      <QuillEditor
        className={classes.editor}
        onChange={handleChange}
        placeholder="Email notification to customer"
        value={messageBody}
      />
      <Divider />
      <Box display="flex" alignItems="center" px={2}>
        <Tooltip title="Attach image">
          <IconButton size="small" className={classes.action}>
            <AddPhotoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Attach file">
          <IconButton size="small" className={classes.action}>
            <AttachFileIcon />
          </IconButton>
        </Tooltip>

        <Box flexGrow={1} />
        {fullScreen ? (
          <IconButton onClick={handleExitFullScreen}>
            <SvgIcon fontSize="small">
              <MinimizeIcon />
            </SvgIcon>
          </IconButton>
        ) : (
          <IconButton onClick={handleEnterFullScreen}>
            <SvgIcon fontSize="small">
              <MaximizeIcon />
            </SvgIcon>
          </IconButton>
        )}
        <IconButton onClick={handleClose}>
          <SvgIcon fontSize="small">
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Box>
    </Paper>
  );
};

Compose.propTypes = {
  importantTracks: PropTypes.array,
};

export default forwardRef(Compose);
