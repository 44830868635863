import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { TIME_FORMAT } from '../../../utils/time';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Profile = ({ rep, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={rep.avatar} />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {rep.firstName} {rep.lastName}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Active: {`${rep.is_active}`} <br />
            Verified: {`${rep.is_verified}`} <br />
            User Id: {`${rep.user_id}`} <br />
            Last Login: {moment().format(TIME_FORMAT)}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  rep: PropTypes.object,
  className: PropTypes.string,
};

export default Profile;
