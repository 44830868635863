import { useRecordMetric } from './metricHooks';
import { INSTRUMENTS } from 'src/types/measurement';
import { useEffect, useRef } from 'react';

type ElapsedTimeProps = {
  metricName: INSTRUMENTS;
};

const useElapsedTime = ({ metricName }: ElapsedTimeProps) => {
  const startTimeRef = useRef(0);
  const recordMetric = useRecordMetric();

  const handleElapsedTime = () => {
    if (startTimeRef.current === 0) return;
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - startTimeRef.current;
    startTimeRef.current = 0;

    recordMetric({
      name: metricName,
      value: elapsedTime,
    });
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      handleElapsedTime();
    }
  };

  useEffect(() => {
    startTimeRef.current = new Date().getTime();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      handleElapsedTime();

      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default useElapsedTime;
