import React, { useCallback } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useCameraSettings, useCameraUpgradeFW } from 'src/hooks/cameraHooks';
import { useListPcsEventsQuery } from 'src/hooks/eventHooks';
import moment from 'moment-timezone';

const CameraConnectivityDetails = ({ camera }) => {
  const { data } = useCameraSettings(camera.uuid, camera.sid);
  const setCameraUpgradeFW = useCameraUpgradeFW(camera.uuid, 'upgrade_fw');
  const setCameraRevertFW = useCameraUpgradeFW(camera.uuid, 'revert_fw');

  const params = {
    skip: 0,
    limit: 10,
    lookback: 60 * 60 * 24 * 10,
    sid: camera.sid,
    searchBy: camera.uuid,
  };
  const eventData = useListPcsEventsQuery(params);

  const getLastStreamed = useCallback(() => {
    if (!eventData?.data?.length) {
      return 'N/A';
    }
    return moment
      .unix(eventData.data[0].eventTimestamp)
      .format('DD/MM/YYYY hh:mm:ss A');
  }, [eventData]);

  const handleUpgradeFirmware = () => {
    setCameraUpgradeFW.mutate({
      uuid: camera.uuid,
    });
  };
  const handleRevertFirmware = () => {
    setCameraRevertFW.mutate({
      uuid: camera.uuid,
    });
  };

  return (
    <>
      <Typography
        variant="h2"
        style={{ color: 'rgba(255, 255, 255, 0.7)', marginBottom: 10 }}
      >
        Connectivity
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Typography>
          Firmware version: {camera.cameraStatus.firmwareVersion}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          disabled={false}
          style={{ textTransform: 'none', fontSize: 15 }}
          onClick={handleUpgradeFirmware}
        >
          Apply Dagger FW
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={false}
          style={{ textTransform: 'none', fontSize: 15 }}
          onClick={handleRevertFirmware}
        >
          Apply SimpliSafe FW
        </Button>
      </div>
      <Typography className={'fs-exclude'}>
        Wifi network: {data?.wifiSsid}
      </Typography>
      <Typography>
        Last connected:{' '}
        {moment
          .unix(camera.cameraStatus.lastLogin)
          .format('DD/MM/YYYY hh:mm:ss A')}
      </Typography>
      <Typography>Last streamed: {getLastStreamed()}</Typography>
    </>
  );
};

export default CameraConnectivityDetails;
