import {MeasurementType, MeasurementDescription, MetricType, 
   INSTRUMENTS,
    Tag,
    METRIC_INTERFACES,
    MeasurementKey,
    IMeasurement
    } from 'src/types/measurement';

/**
 * Create concrete instances of the Metrics in METRIC_INTERFACES, which are then used in metricHooks.ts to take a measurement
 * The frontend does not directly use Milestones as an OTEL object, so Milestones are not actually instantiated
 */

// Event Received
const eventReceived: METRIC_INTERFACES[INSTRUMENTS.EVENT_RECEIVED] = {
  name: INSTRUMENTS.EVENT_RECEIVED,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
  description:  MeasurementDescription[INSTRUMENTS.EVENT_RECEIVED],
  tags: [Tag.LOCATION_ID, Tag.CAMERA_UUID, Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Ignored
const eventIgnored: METRIC_INTERFACES[INSTRUMENTS.EVENT_IGNORED] = {
  name: INSTRUMENTS.EVENT_IGNORED,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_IGNORED],
  tags: [
  Tag.LOCATION_ID,
  Tag.CAMERA_UUID,
  Tag.CAMERA_MODEL,
  Tag.CAMERA_FIRMWARE,
  Tag.DROP_REASON,
],
  key: MeasurementKey.EVENT,
metricType: MetricType.COUNTER,
}

// Event Propagation Delay
const eventPropagationDelay: METRIC_INTERFACES[INSTRUMENTS.EVENT_PROPAGATION_DELAY] = {
name: INSTRUMENTS.EVENT_PROPAGATION_DELAY,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENT_PROPAGATION_DELAY],
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE],
key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}

// Event Auto Filtered
  const eventAutoFiltered: METRIC_INTERFACES[INSTRUMENTS.EVENT_AUTO_FILTERED] = {
name: INSTRUMENTS.EVENT_AUTO_FILTERED,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_AUTO_FILTERED],
  tags: [
  Tag.LOCATION_ID,
  Tag.CAMERA_UUID,
  Tag.CAMERA_MODEL,
  Tag.CAMERA_FIRMWARE,
  Tag.AUTO_FILTER_REASON,
],
  key: MeasurementKey.EVENT,
metricType: MetricType.COUNTER,
}

// Event Time to Auto Filter
  const eventTimeToAutoFilter: METRIC_INTERFACES[INSTRUMENTS.EVENT_TIME_TO_AUTO_FILTER] = {
name: INSTRUMENTS.EVENT_TIME_TO_AUTO_FILTER,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENT_TIME_TO_AUTO_FILTER],
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.AUTO_FILTER_REASON],
key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}

// Event Held
  const eventHeld: METRIC_INTERFACES[INSTRUMENTS.EVENT_HELD] = {
name: INSTRUMENTS.EVENT_HELD,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_HELD],
  tags: [
  Tag.LOCATION_ID,
  Tag.CAMERA_UUID,
  Tag.CAMERA_MODEL,
  Tag.CAMERA_FIRMWARE,
  Tag.OTHER_EVENT_ID,
  Tag.PREVIOUS_STATE,
  Tag.ACTIVE_AGENT_ID,
  Tag.HOLD_REASON,
],
  key: MeasurementKey.EVENT,
metricType: MetricType.COUNTER,
}

// Event Unheld
const eventUnheld: METRIC_INTERFACES[INSTRUMENTS.EVENT_UNHELD] = {
name: INSTRUMENTS.EVENT_UNHELD,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_UNHELD],
  tags: [
  Tag.LOCATION_ID,
  Tag.CAMERA_UUID,
  Tag.CAMERA_MODEL,
  Tag.CAMERA_FIRMWARE,
  Tag.OTHER_EVENT_ID,
  Tag.PREVIOUS_STATE,
  Tag.ACTIVE_AGENT_ID,
  Tag.HOLD_REASON,
],
key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Got First Frame
const eventGotFirstFrame: METRIC_INTERFACES[INSTRUMENTS.EVENT_GOT_FIRST_FRAME] = {
name: INSTRUMENTS.EVENT_GOT_FIRST_FRAME,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_GOT_FIRST_FRAME],
  tags: [
  Tag.LOCATION_ID,
  Tag.CAMERA_UUID,
  Tag.CAMERA_MODEL,
  Tag.CAMERA_FIRMWARE,
  Tag.CAMERA_BACKEND_TYPE,
],
  key: MeasurementKey.EVENT,
metricType: MetricType.COUNTER,
}

// Event Time to First Frame for Processing
const eventTimeToFirstFrameForProcessing: METRIC_INTERFACES[INSTRUMENTS.EVENT_TIME_TO_FIRST_FRAME_FOR_PROCESSING] = {
name: INSTRUMENTS.EVENT_TIME_TO_FIRST_FRAME_FOR_PROCESSING,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENT_TIME_TO_FIRST_FRAME_FOR_PROCESSING],
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.CAMERA_BACKEND_TYPE],
key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}

// Event Time to AI Decision
const eventTimeToAIDecision: METRIC_INTERFACES[INSTRUMENTS.EVENT_TIME_TO_AI_DECISION] = {
name: INSTRUMENTS.EVENT_TIME_TO_AI_DECISION,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENT_TIME_TO_AI_DECISION],
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.CAMERA_BACKEND_TYPE, Tag.DECISION_MADE, Tag.MODEL_MAKING_DECISION],
key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}

// Event Time Spent in Feed
const eventTimeSpentInFeed: METRIC_INTERFACES[INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED] = {
  name: INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED,
  types: [MeasurementType.METRIC],
  description: typeof MeasurementDescription[INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED],
  tags: [],
  key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}


// Event Video Processing Error
const eventVideoProcessingError: METRIC_INTERFACES[INSTRUMENTS.EVENT_VIDEO_PROCESSING_ERROR] = {
name: INSTRUMENTS.EVENT_VIDEO_PROCESSING_ERROR,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_VIDEO_PROCESSING_ERROR],
  tags: [
  Tag.LOCATION_ID,
  Tag.CAMERA_UUID,
  Tag.CAMERA_MODEL,
  Tag.CAMERA_FIRMWARE,
  Tag.ERROR_TYPE
],
  key: MeasurementKey.EVENT,
metricType: MetricType.COUNTER,
}

// Event Completed Initial Video Processing
const eventCompletedInitialVideoProcessing: METRIC_INTERFACES[INSTRUMENTS.EVENT_COMPLETED_INITIAL_VIDEO_PROCESSING] = {
name: INSTRUMENTS.EVENT_COMPLETED_INITIAL_VIDEO_PROCESSING,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_COMPLETED_INITIAL_VIDEO_PROCESSING],
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE],
key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Time to Complete Initial Video Processing
const eventTimeToCompleteInitialVideoProcessing: METRIC_INTERFACES[INSTRUMENTS.EVENT_TIME_TO_COMPLETE_INITIAL_VIDEO_PROCESSING] = {
  name: INSTRUMENTS.EVENT_TIME_TO_COMPLETE_INITIAL_VIDEO_PROCESSING,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENT_TIME_TO_COMPLETE_INITIAL_VIDEO_PROCESSING],
  tags: [Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE],
key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}

// Event Ready to Send to Agent
const eventReadyToSendToAgent: METRIC_INTERFACES[INSTRUMENTS.EVENT_READY_TO_SEND_TO_AGENT] = {
name: INSTRUMENTS.EVENT_READY_TO_SEND_TO_AGENT,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_READY_TO_SEND_TO_AGENT],
  tags: [Tag.LOCATION_ID, Tag.CAMERA_UUID, Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.THREAT_LEVEL],
key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Threat Level Changed
const eventThreatLevelChanged: METRIC_INTERFACES[INSTRUMENTS.EVENT_THREAT_LEVEL_CHANGED] = {
name: INSTRUMENTS.EVENT_THREAT_LEVEL_CHANGED,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENT_THREAT_LEVEL_CHANGED],
  tags: [Tag.PREVIOUS_THREAT_LEVEL, Tag.NEW_THREAT_LEVEL],
key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Presented to Agent
const eventPresentedToAgent: METRIC_INTERFACES[INSTRUMENTS.EVENT_PRESENTED_TO_AGENT] = {
name: INSTRUMENTS.EVENT_PRESENTED_TO_AGENT,
  types: [MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_PRESENTED_TO_AGENT],
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID, Tag.TOTAL_AVAILABLE_SLOTS, Tag.TOTAL_FILLED_SLOTS],
  key: MeasurementKey.EVENT,
  metricType: null,
}

// Event Exited Feed
const eventExitedFeed: METRIC_INTERFACES[INSTRUMENTS.EVENT_EXITED_FEED] = {
name: INSTRUMENTS.EVENT_EXITED_FEED,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_EXITED_FEED],
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID, Tag.REASON, Tag.DISPOSITION_ACTION, Tag.TIME_SPENT_IN_FEED, Tag.CANCEL_REASON],
key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Started Verification
const eventStartedVerification: METRIC_INTERFACES[INSTRUMENTS.EVENT_STARTED_VERIFICATION] = {
name: INSTRUMENTS.EVENT_STARTED_VERIFICATION,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_STARTED_VERIFICATION],
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Ended Verification
const eventEndedVerification: METRIC_INTERFACES[INSTRUMENTS.EVENT_ENDED_VERIFICATION] = {
name: INSTRUMENTS.EVENT_ENDED_VERIFICATION,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_ENDED_VERIFICATION],
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID, Tag.REASON, Tag.DISPOSITION_ACTION, Tag.CANCEL_REASON],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Verification Duration
const eventVerificationDuration: METRIC_INTERFACES[INSTRUMENTS.EVENT_VERIFICATION_DURATION] = {
name: INSTRUMENTS.EVENT_VERIFICATION_DURATION,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENT_VERIFICATION_DURATION],
  tags: [],
  key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}

// Event Started Followup
const eventStartedFollowup: METRIC_INTERFACES[INSTRUMENTS.EVENT_STARTED_FOLLOWUP] = {
name: INSTRUMENTS.EVENT_STARTED_FOLLOWUP,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_STARTED_FOLLOWUP],
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Ended Followup
const eventEndedFollowup: METRIC_INTERFACES[INSTRUMENTS.EVENT_ENDED_FOLLOWUP] = {
name: INSTRUMENTS.EVENT_ENDED_FOLLOWUP,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_ENDED_FOLLOWUP],
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID, Tag.REASON, Tag.DISPOSITION_ACTION, Tag.CANCEL_REASON],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Event Followup Duration
const eventFollowupDuration: METRIC_INTERFACES[INSTRUMENTS.EVENT_FOLLOWUP_DURATION] = {
name: INSTRUMENTS.EVENT_FOLLOWUP_DURATION,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENT_FOLLOWUP_DURATION],
  tags: [],
  key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}

// Event Dispositioned
const eventDispositioned: METRIC_INTERFACES[INSTRUMENTS.EVENT_DISPOSITIONED] = {
name: INSTRUMENTS.EVENT_DISPOSITIONED,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
description: MeasurementDescription[INSTRUMENTS.EVENT_DISPOSITIONED],
  tags: [Tag.LOCATION_ID, Tag.ACTIVE_AGENT_ID, Tag.DISPOSITION_ACTION],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Events Returned in Take
const eventsReturnedInTake: METRIC_INTERFACES[INSTRUMENTS.EVENTS_RETURNED_IN_TAKE] = {
name: INSTRUMENTS.EVENTS_RETURNED_IN_TAKE,
  types: [MeasurementType.METRIC],
description: MeasurementDescription[INSTRUMENTS.EVENTS_RETURNED_IN_TAKE],
  tags: [],
  key: MeasurementKey.AGENT,
  metricType: MetricType.COUNTER,
}

// Agent Websocket Connected
const agentWebsocketConnected: METRIC_INTERFACES[INSTRUMENTS.AGENT_WEBSOCKET_CONNECTED] = {
  name: INSTRUMENTS.AGENT_WEBSOCKET_CONNECTED,
  types: [MeasurementType.METRIC],
  description: MeasurementDescription[INSTRUMENTS.AGENT_WEBSOCKET_CONNECTED],
  tags: [],
  key: MeasurementKey.AGENT,
  metricType: MetricType.COUNTER,
}

// Agent Websocket Disconnected
const agentWebsockedDisconnected: METRIC_INTERFACES[INSTRUMENTS.AGENT_WEBSOCKET_DISCONNECTED] = {
  name: INSTRUMENTS.AGENT_WEBSOCKET_DISCONNECTED,
  types: [MeasurementType.METRIC],
  description: MeasurementDescription[INSTRUMENTS.AGENT_WEBSOCKET_DISCONNECTED],
  tags: [],
  key: MeasurementKey.AGENT,
  metricType: MetricType.COUNTER,
}

// Agent Deterrent Activated
const agentDeterrentActivated: METRIC_INTERFACES[INSTRUMENTS.AGENT_DETERRENT_ACTIVATED] = {
  name: INSTRUMENTS.AGENT_DETERRENT_ACTIVATED,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
  description: MeasurementDescription[INSTRUMENTS.AGENT_DETERRENT_ACTIVATED],
  tags: [Tag.LOCATION_ID, Tag.CAMERA_UUID, Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.ACTIVE_AGENT_ID, Tag.CAMERA_BACKEND_TYPE, Tag.DETERRENT_TYPE],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Agent 2-Way Audio Unmute
const agent2WayAudioUnmute: METRIC_INTERFACES[INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE] = {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
  description: MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE],
  tags: [Tag.LOCATION_ID, Tag.CAMERA_UUID, Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.ACTIVE_AGENT_ID, Tag.CAMERA_BACKEND_TYPE],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Agent 2-Way Audio Mute
const agent2WayAudioMute: METRIC_INTERFACES[INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE] = {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
  description: MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE],
  tags: [Tag.LOCATION_ID, Tag.CAMERA_UUID, Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.ACTIVE_AGENT_ID, Tag.CAMERA_BACKEND_TYPE],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Agent 2-Way Audio Connection Success
const agent2WayAudioConnectionSuccess: METRIC_INTERFACES[INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS] = {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
  description: MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS],
  tags: [Tag.LOCATION_ID, Tag.CAMERA_UUID, Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.ACTIVE_AGENT_ID, Tag.CAMERA_BACKEND_TYPE],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Agent 2-Way Audio Connection Failure
const agent2WayAudioConnectionFailure: METRIC_INTERFACES[INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE] = {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE,
  types: [MeasurementType.METRIC, MeasurementType.MILESTONE],
  description: MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE],
  tags: [Tag.LOCATION_ID, Tag.CAMERA_UUID, Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.ACTIVE_AGENT_ID, Tag.CAMERA_BACKEND_TYPE],
  key: MeasurementKey.EVENT,
  metricType: MetricType.COUNTER,
}

// Agent 2-Way Audio Connection Duration
const agent2WayAudioDuration: METRIC_INTERFACES[INSTRUMENTS.AGENT_2_WAY_AUDIO_DURATION] = {
  name: INSTRUMENTS.AGENT_2_WAY_AUDIO_DURATION,
  types: [MeasurementType.METRIC],
  description: MeasurementDescription[INSTRUMENTS.AGENT_2_WAY_AUDIO_DURATION],
  tags: [Tag.LOCATION_ID, Tag.CAMERA_UUID, Tag.CAMERA_MODEL, Tag.CAMERA_FIRMWARE, Tag.ACTIVE_AGENT_ID, Tag.CAMERA_BACKEND_TYPE],
  key: MeasurementKey.EVENT,
  metricType: MetricType.HISTOGRAM,
}

// Events in New State
const eventsInNewState: METRIC_INTERFACES[INSTRUMENTS.EVENTS_IN_NEW_STATE] = {
  name: INSTRUMENTS.EVENTS_IN_NEW_STATE,
  types: [MeasurementType.METRIC],
  description: MeasurementDescription[INSTRUMENTS.EVENTS_IN_NEW_STATE],
  tags: [],
  key: MeasurementKey.EVENT,
  metricType: MetricType.GAUGE,
}

export const METRIC_INSTRUMENTS: IMeasurement[] = [
    eventReceived, 
    eventIgnored,
    eventPropagationDelay,
    eventAutoFiltered,
    eventTimeToAutoFilter,
    eventHeld,
    eventUnheld,
    eventGotFirstFrame,
    eventTimeToFirstFrameForProcessing,
    eventTimeToAIDecision,
    eventTimeSpentInFeed,
    eventVideoProcessingError,
    eventCompletedInitialVideoProcessing,
    eventTimeToCompleteInitialVideoProcessing,
    eventReadyToSendToAgent,
    eventThreatLevelChanged,
    eventPresentedToAgent,
    eventExitedFeed,
    eventStartedVerification,
    eventEndedVerification,
    eventVerificationDuration,
    eventStartedFollowup,
    eventEndedFollowup,
    eventFollowupDuration,
    eventDispositioned,
    eventsReturnedInTake,
    agentWebsocketConnected,
    agentWebsockedDisconnected,
    agentDeterrentActivated,
    agent2WayAudioUnmute,
    agent2WayAudioMute,
    agent2WayAudioConnectionSuccess,
    agent2WayAudioConnectionFailure,
    agent2WayAudioDuration,
    eventsInNewState,
]
