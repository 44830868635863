type EventMilestoneData = {
  metricName: string;
  eventId: string;
  payload?: {
    [key: string]: string | number;
  };
};

type AgentMilestoneData = {
  metricName: string;
  agentId: string;
  payload?: {
    [key: string]: string | number;
  };
};

export const useEventMilestone = () => {
  const sendEventMilestone = async ({
    metricName,
    eventId,
    payload,
  }: EventMilestoneData) => {
    const eventPayload = {
      metricName,
      eventId: Number.parseInt(eventId),
      source: 'agent_portal',
      payload,
    };
    if (payload?.agent_id != null && typeof payload.agent_id !== 'number') {
      eventPayload.payload.agent_id = Number.parseInt(payload.agent_id);
    }
    if (
      payload?.location_id != null &&
      typeof payload.location_id !== 'number'
    ) {
      eventPayload.payload.location_id = Number.parseInt(payload.location_id);
    }

    navigator.sendBeacon(
      `${process.env.REACT_APP_PCS_ADMIN_URL}/metrics/events`,
      JSON.stringify(eventPayload)
    );
  };

  return sendEventMilestone;
};

export const useAgentMilestone = () => {
  const sendAgentMilestone = async ({
    metricName,
    agentId,
    payload,
  }: AgentMilestoneData) => {
    const agentPayload = {
      metricName,
      agentId: Number.parseInt(agentId),
      source: 'agent_portal',
      payload,
    };
    navigator.sendBeacon(
      `${process.env.REACT_APP_PCS_ADMIN_URL}/metrics/agents`,
      JSON.stringify(agentPayload)
    );
  };

  return sendAgentMilestone;
};
