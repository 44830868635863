import { useAddCounter, useRecordMetric } from './metricHooks';
import { INSTRUMENTS } from 'src/types/measurement';

import { useEffect, useRef } from 'react';
import { useEventMilestone } from './milestoneHooks';
import { PcsStatus } from 'src/models';

/*
  Any time an event exits the feed, which can be due to any of the following mechanisms:
  * Canceled from feed (including if the system cancels an event automatically, such as from a disarm or intrusion handle)
  * Moved to V&R from feed
  * Moved to HOLD status while in feed
  * Bounced from feed (see definition below)
  * Expired while in feed
  * Logged out while on feed
  * Tab refresh while in feed
  * Tab/window close while in feed

  An event is considered "bounced" from feed when an event is one of multiple in feed, and the specialist starts verifying a different event from that set than this one.
  */

export enum EventExitedFeedReason {
  CANCELED_FROM_FEED = 'canceled_from_feed',
  MOVED_TO_VR = 'moved_to_vr',
  MOVED_TO_HOLD = 'moved_to_hold',
  BOUNCED_FROM_FEED = 'bounced_from_feed',
  EXPIRED = 'expired',
  LOGGED_OUT = 'logged_out',
  TAB_REFRESH = 'tab_refresh',
  TAB_CLOSE = 'tab_close',
}

type EventExitedFeedPayload = {
  reason: EventExitedFeedReason;
  disposition_action?: string;
  disposition_reason?: string;
};

const useEventExitedFeed = ({
  eventId,
  sid,
  pcs_status,
}: {
  eventId: string;
  sid: string;
  pcs_status: PcsStatus;
}) => {
  const startTimeRef = useRef(0);
  const addCounter = useAddCounter();
  const addHistogram = useRecordMetric();
  const sendEventMetrics = useEventMilestone();

  const metricSent = useRef(false);

  const handleEventExited = ({
    reason,
    disposition_action = undefined,
    disposition_reason = undefined,
  }: EventExitedFeedPayload) => {
    if (startTimeRef.current === 0 || metricSent.current) return;
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - startTimeRef.current;
    startTimeRef.current = 0;
    metricSent.current = true;

    addCounter({
      name: INSTRUMENTS.EVENT_EXITED_FEED,
      value: 1,
      attributes: {
        reason,
        disposition_action,
        disposition_reason,
      },
    });

    addHistogram({
      name: INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED,
      value: elapsedTime,
    });

    sendEventMetrics({
      eventId: eventId,
      metricName: INSTRUMENTS.EVENT_EXITED_FEED,
      payload: {
        agent_id: localStorage.getItem('user_id'),
        location_id: sid,
        reason: reason,
        time_spent_in_feed: elapsedTime,
        disposition_action: disposition_action,
        cancel_reason: disposition_reason ?? '',
      },
    });
  };

  const handleTabClose = () => {
    if (document.visibilityState === 'hidden') {
      handleEventExited({
        reason: EventExitedFeedReason.TAB_CLOSE,
      });
    }
  };

  const handleTabRefresh = () => {
    const [navigationEntry] = performance.getEntriesByType(
      'navigation'
    ) as PerformanceNavigationTiming[];
    if (navigationEntry?.type === 'reload') {
      handleEventExited({
        reason: EventExitedFeedReason.TAB_REFRESH,
      });
    }
  };

  const handleLogout = () => {
    handleEventExited({
      reason: EventExitedFeedReason.LOGGED_OUT,
    });
  };

  useEffect(() => {
    startTimeRef.current = new Date().getTime();

    window.addEventListener('beforeunload', handleTabRefresh);
    window.addEventListener('logout', handleLogout);

    return () => {
      window.removeEventListener('beforeunload', handleTabRefresh);
      window.removeEventListener('logout', handleLogout);
      handleTabClose();
      if (!metricSent.current) {
        handleEventExited({
          reason:
            pcs_status === PcsStatus.canceled
              ? EventExitedFeedReason.CANCELED_FROM_FEED
              : EventExitedFeedReason.BOUNCED_FROM_FEED,
        });
      }
    };
  }, []);

  return {
    handleEventExited,
  };
};

export default useEventExitedFeed;
