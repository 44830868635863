import React from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {toMbpsSpeed} from 'src/utils/camera';
import { getCustomerFormatedDateAndTime } from '../../utils/time';

const CameraSpeedTestResults = ({ speedTestResult }) => {
  return (
    <TableContainer>
      <Table aria-label="simple table" padding="none">
        <TableBody>
          {speedTestResult.map((item: any) => (
            <React.Fragment key={item._id}>
              <TableRow>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  RSSI:
                </TableCell>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  {item.metrics.rssi}dBm
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  Download Speed:
                </TableCell>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  {toMbpsSpeed(item.metrics.downloadSpeed)} Mbps
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  Upload Speed:
                </TableCell>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  {toMbpsSpeed(item.metrics.uploadSpeed)} Mbps
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  Reported At:
                </TableCell>
                <TableCell style={{ borderBottom: 'none', fontSize: 14 }}>
                  {getCustomerFormatedDateAndTime(item.created)}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CameraSpeedTestResults;
