import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import {
  // AlertCircle as AlertCircleIcon,
  // BarChart as BarChartIcon,
  // Lock as LockIcon,
  // Settings as SettingsIcon,
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  Clipboard as ClipboardIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
  Send as SendIcon,
} from 'react-feather';
import { Role } from 'src/models';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ListIcon from '@material-ui/icons/List';
import AppsIcon from '@material-ui/icons/Apps';
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// import PeopleIcon from '@material-ui/icons/People';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import LayersIcon from "@material-ui/icons/Layers";
// import AssignmentIcon from "@material-ui/icons/Assignment";
import WebIcon from '@material-ui/icons/Web';
import CreateIcon from '@material-ui/icons/Create';
import SettingsIcon from '@material-ui/icons/Settings';
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
// import VideocamIcon from '@material-ui/icons/Videocam';
// import ViewComfyIcon from '@material-ui/icons/ViewComfy';

import NavItem from './NavItem';
import { useGetRepMe } from 'src/hooks/repHooks';

const isProduction = process.env.REACT_APP_BUILD_ENV === 'production';

const items = [
  {
    href: '/app/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard',
  },
  {
    href: '/app/subs',
    icon: UsersIcon,
    title: 'Users (subs)',
  },
  {
    href: '/app/event-list',
    icon: ListIcon,
    title: 'Events',
  },
  {
    href: '/app/audit-list',
    icon: ClipboardIcon,
    title: 'Logs',
  },
  {
    href: '/app/reps-list',
    icon: UserCheckIcon,
    title: 'Manage reps',
  },
  {
    href: '/app/invites',
    icon: SendIcon,
    title: 'Invite users',
  },
  // {
  //   href: '/app/reps',
  //   icon: UsersIcon,
  //   title: 'Reps (agents)'
  // },
  // {
  //   href: '/app/reports',
  //   icon: BarChartIcon,
  //   title: 'Reports'
  // },
  // {
  //   href: '/app/webcam',
  //   icon: PhotoCameraIcon,
  //   title: 'Webcam'
  // },
  // {
  //   href: '/app/video',
  //   icon: VideocamIcon,
  //   title: 'Video'
  // },
  // {
  //   href: '/app/cameras',
  //   icon: ViewComfyIcon,
  //   title: 'Cameras'
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 200,
  },
  desktopDrawer: {
    width: 200,
    top: 26,
    height: 'calc(100% - 26px)',
    position: 'relative' /* remove if we want menu to go over */,
  },
  desktopDrawerClosed: {
    width: 32,
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    flexDirection: 'unset',
    '& a': {
      paddingLeft: '5px',
    },
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { data: repData } = useGetRepMe();

  const secondaryItems = [
    {
      href: '/app/queue',
      icon: AppsIcon,
      disabled: false,
      title: 'Queue',
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Divider />
      <Box p={0}>
        <List>
          {items
            .filter((item) => {
              if (
                repData?.role !== Role.ADMIN &&
                item.href === '/app/invites'
              ) {
                return false;
              }
              return true;
            })
            .filter((item) => {
              if (
                repData?.role === Role.AGENT_SUPERVISOR &&
                item.href === '/app/subs'
              ) {
                return false;
              }

              return true;
            })
            .filter((item) => {
              if (
                repData?.role === Role.AGENT_OPS &&
                (item.href === '/app/event-list' ||
                  item.href === '/app/subs' ||
                  item.href === '/app/reps-list' ||
                  item.href === '/app/audit-list')
              ) {
                return false;
              }
              return true;
            })
            .filter((item) => {
              if (
                repData?.role === Role.QA &&
                (item.href === '/app/subs' || item.href === '/app/reps-list')
              ) {
                return false;
              }
              return true;
            })
            .map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                selected={item.href === location.pathname}
              />
            ))}
        </List>
        <Divider />
        <ListSubheader>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Alarm Monitoring
        </ListSubheader>
        <List>
          {secondaryItems
            .filter((item) => {
              if (
                repData?.role === Role.QA &&
                (item.href === '/app/queue' ||
                  item.href === '/app/verify-respond' ||
                  item.href === '/app/follow-up')
              ) {
                return false;
              }
              return true;
            })
            .map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                disabled={item.disabled}
                title={item.title}
                icon={item.icon}
              />
            ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={0}>
        <NavItem
          href="/app/settings"
          key={1}
          title="Settings"
          icon={SettingsIcon}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden smUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          // variant="persistent"
          // variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          anchor="left"
          // classes={{ paper: classes.desktopDrawer }}
          classes={{
            paper: clsx(
              classes.desktopDrawer,
              !openMobile && classes.desktopDrawerClosed
            ),
          }}
          open={openMobile}
          onClose={onMobileClose}
          // variant="persistent"
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
