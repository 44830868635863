import { useAuth0 } from '@auth0/auth0-react';
import opentelemetry from '@opentelemetry/api';
import React, { useEffect, useState } from 'react';
import {
  clearAuthCookie,
  clearLocalStorage,
  saveAuthCookie,
  saveUserDataInLocalStorage,
} from 'src/utils/auth';

const PcsAuthContext = React.createContext();
PcsAuthContext.displayName = 'PcsAuthContext';

const PcsAuthProvider = ({ children }) => {
  const { logout, getAccessTokenSilently } = useAuth0();
  const [rep, setRep] = useState({
    token: localStorage.getItem('token'),
    id: localStorage.getItem('id'),
    user_id: localStorage.getItem('user_id'),
    role: localStorage.getItem('role'),
    logout: () => {
      // emit a javascript event to the window to logout of the app
      window.dispatchEvent(new Event('logout'));
      logout();
      clearLocalStorage();
      clearAuthCookie();
    },
  });

  useEffect(() => {
    const hourMs = 60 * 60 * 1000;
    const refreshInterval = setInterval(async () => {
      // The getAccessTokenSilently method retrieves the access token from Auth0 if it's expired
      const token = await getAccessTokenSilently();
      saveUserDataInLocalStorage({ token });
      saveAuthCookie(token);
      const refreshedRep = { token };
      setRep((prevRep) => ({ ...prevRep, ...refreshedRep }));
    }, hourMs);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <PcsAuthContext.Provider value={rep}>{children}</PcsAuthContext.Provider>
  );
};

/**
 * Hook to get authorized rep user data
 *
 * @returns rep user data as context values {id, user_id, token, logout function}
 * @throws Error if PcsAuthProvider doesn't exist
 */
function usePcsAuthRep() {
  const context = React.useContext(PcsAuthContext);
  if (context === undefined) {
    throw new Error(`usePcsAuth must be used within a PcsAuthProvider`);
  }
  return context;
}

// *** Hook to get authorized rep user data ***
function usePcsClient() {
  const { token, logout } = usePcsAuthRep();

  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }, logout),
    [token]
  );
}

/**
 * Client used for making HTTP requests to PCS server
 *
 * @param endpoint
 * @param { data, token, }
 * @returns response data
 * @throws Error on http errors or failed attempts
 */
async function client(
  endpoint,
  { data, params, token, headers: customHeaders, ...customConfig } = {},
  onUnauthorized
) {
  const apiURL = process.env.REACT_APP_PCS_ADMIN_URL;
  const config = {
    method: data ? 'POST' : 'GET',
    // data: data,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };
  if (data) {
    config.body = JSON.stringify(data);
  }
  if (params) {
    endpoint += `?${new URLSearchParams(params)}`;
  }

  return window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        console.log(`401 Not Authorized on PCS Client, logout and go to /`);

        onUnauthorized();
        window.location.assign('/');
        const error = new Error(
          `Error caught: Unauthorized PCS call to ${endpoint}`
        );
        recordException(error);
        return Promise.reject(error);
      }
      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json();
        } else if (contentType && contentType.indexOf('image/png') !== -1) {
          return response.blob();
        } else {
          return response.text();
        }
      } else {
        const errorMessage = await response.text();
        const error = new Error(errorMessage);
        Error.captureStackTrace(error);
        recordException(error);
        return Promise.reject(errorMessage);
      }
    });
}

function recordException(error) {
  const activeSpan = opentelemetry.trace.getActiveSpan();
  activeSpan?.recordException(error);
}

export { PcsAuthProvider, usePcsAuthRep, usePcsClient };
