import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import {
  useTakePcsEventsQuery,
  useUnsetEventsTaken,
} from 'src/hooks/eventHooks';
import { Theme } from 'src/theme/index';
import MonitorItem from './MonitorItem';
import EmptyMonitorItem from './EmptyMonitorItem';
import { useVideoPlayer } from 'src/components/VideoPlayer/useVideoPlayer';
import { useGetRepMe, useGetQueueSettings } from 'src/hooks/repHooks';
import { setEventsDataAC } from 'src/context/socket-context';
import { SocketContext } from 'src/context/socket-context';
import { Navigate } from 'react-router';
import { QueueControls } from './QueueControls';
import { Role } from 'src/models';
import { FullStory as FS } from '@fullstory/browser';
import useEmptyEventQueueTimeMetric from './useEmptyEventQueueTimeMetric';
import { useQueueTimeMetrics } from './useQueueTimeMetric';
import { useEventHandleTimeMetrics } from './useEventHandleTimeMetric';
import { useEventQueueConcurrencyMetric } from './useEventQueueConcurrencyMetric';
import { INSTRUMENTS } from 'src/types/measurement';
import { useAgentMilestone, useEventMilestone } from 'src/hooks/milestoneHooks';
import useAgentExitedFeed, {
  AgentExitedFeedReason,
} from 'src/hooks/useAgentExitedFeed';

import useAgentWorkState, {
  WorkStateAction,
  WorkStateExitAction,
} from 'src/hooks/useAgentWorkState';

export enum AlarmState {
  ALL = 'All',
  UNARMED = 'Unarmed',
  ARMED = 'Armed',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
}));

const MonitorView = () => {
  const classes = useStyles();
  const { data: repData } = useGetRepMe();
  const [eventArray2, socketdispatch] = useContext(SocketContext);
  const { submitEmptyQueueTimeMetric, retrigger: retriggerEmptyQueueMetric } =
    useEmptyEventQueueTimeMetric({
      agentId: repData?.id,
      agentEmail: repData?.email,
    });

  // Get settings from localstorage
  const pcs_watch_settings = useGetQueueSettings(repData?.id);
  const [limit, setLimit] = useState(pcs_watch_settings.data?.limit);
  const [acceptNew, setAcceptNew] = useState(
    pcs_watch_settings.data?.acceptNew
  );
  const [lookback, setLookback] = useState(pcs_watch_settings.data?.lookback);
  const [autoExpire, setAutoExpire] = useState(
    pcs_watch_settings.data?.autoExpire
  );
  const [pcsStatusThreshold, setPcsStatusThreshold] = useState(
    pcs_watch_settings.data?.pcsStatusThreshold
  );
  const [threatLevel, setThreatLevel] = useState(
    pcs_watch_settings.data?.threatLevel
  );
  const [armstate, setArmstate] = useState(pcs_watch_settings.data?.armstate);
  const [experimental, setExperimental] = useState(false);
  const previousScreen = localStorage.getItem('previous_screen') || '';
  const [totalSlots, setTotalSlots] = useState(limit);

  // query events based on settings
  const eventsQuery = useTakePcsEventsQuery(
    limit,
    acceptNew,
    lookback,
    pcsStatusThreshold,
    threatLevel,
    armstate
  );

  // update event array using queried data
  useEffect(() => {
    socketdispatch(setEventsDataAC(eventsQuery.data, acceptNew));
  }, [eventsQuery.data]);

  React.useEffect(() => {
    if (!eventsQuery.isFetching) {
      setTotalSlots(limit);
    }
  }, [limit, eventsQuery.isFetching]);

  const sendAgentMetrics = useAgentMilestone();

  // Identify the user to FullStory
  useEffect(() => {
    if (repData) {
      FS('setIdentity', {
        uid: repData?.user_id,
        properties: {
          userId: repData?.user_id,
          email: repData?.email,
          name: `${repData?.firstName} ${repData?.lastName}`,
        },
      });
    }
  }, []); // Send every time the component mounts, will be more than strictly necessary but will help capture IDs

  const { setEnteredWorkState, setExitedWorkState } = useAgentWorkState();

  // immediately send empty queue time metric when not accepting new events
  useEffect(() => {
    if (acceptNew === true) {
      retriggerEmptyQueueMetric();
      setEnteredWorkState(WorkStateAction.ACCEPT_NEW);
    } else if (acceptNew === false) {
      submitEmptyQueueTimeMetric();
      setExitedWorkState(WorkStateExitAction.ACCEPT_NEW);
      handleAgentExited({ reason: AgentExitedFeedReason.ACCEPT_NEW_OFF });
    }
  }, [acceptNew]);

  const sendEventMetrics = useEventMilestone();
  const eventsPresented =
    eventArray2.eventArray?.filter((_event) => _event.eventId != null) ?? [];
  const previousEventPresentedIds = useRef<Set<string>>(new Set());

  const { handleAgentExited } = useAgentExitedFeed({
    totalUnassignedSlots: totalSlots - eventsPresented.length,
    totalAssignedSlots: eventsPresented.length || 0,
    totalSlots: totalSlots,
    previousScreen: 'queue',
  });

  useEffect(() => {
    if (eventsPresented.length === 0) {
      return;
    }

    for (const _event of eventsPresented) {
      if (previousEventPresentedIds.current.has(_event.eventId)) {
        continue;
      }
      previousEventPresentedIds.current.add(_event.eventId);
      sendEventMetrics({
        metricName: INSTRUMENTS.EVENT_PRESENTED_TO_AGENT,
        eventId: _event.eventId,
        payload: {
          location_id: _event.sid,
          agent_id: localStorage.getItem('user_id'),
          total_unassigned_slots: totalSlots - eventsPresented.length,
          total_assigned_slots: eventsPresented.length || 0,
          total_slots: totalSlots,
        },
      });
    }
  }, [totalSlots, eventsPresented]);

  useEffect(() => {
    if(limit == null) return;
    sendAgentMetrics({
      metricName: INSTRUMENTS.AGENT_FEED_SLOT_OCCUPANCY_CHANGED,
      agentId: localStorage.getItem('user_id'),
      payload: {
        total_slots: limit,
        total_unassigned_slots: limit - eventsPresented.length || 0,
        total_assigned_slots: eventsPresented.length || 0,
        action_name: '',
      },
    });
  }, [totalSlots, eventsPresented.length]);

  // Save settings on any change
  useEffect(() => {
    localStorage.setItem(
      'pcs_watch_settings',
      JSON.stringify({
        limit: 9,
        acceptNew: false,
        lookback: 60 * 10,
        autoExpire: 60 * 2,
        pcsStatusThreshold: 0,
        threatLevel: 0,
        armstate: 'Armed',
      })
    );
  }, [
    limit,
    acceptNew,
    lookback,
    autoExpire,
    pcsStatusThreshold,
    threatLevel,
    armstate,
  ]);

  useEffect(() => {
    setLimit(pcs_watch_settings.data?.limit);
    setAcceptNew(pcs_watch_settings.data?.acceptNew);
    setLookback(pcs_watch_settings.data?.lookback);
    setAutoExpire(pcs_watch_settings.data?.autoExpire);
    setPcsStatusThreshold(pcs_watch_settings.data?.pcsStatusThreshold);
    setThreatLevel(pcs_watch_settings.data?.threatLevel);
    setArmstate(pcs_watch_settings.data?.armstate);
  }, [pcs_watch_settings.data]);

  const visibilityHandler = () => {
    if (document.visibilityState === 'hidden' && acceptNew) {
      setAcceptNew(false);
    }
  };

  // Leaving the page - "un-take" all events
  const eventClickedRef = useRef<number | null>(null);
  const unsetTakenMutation = useUnsetEventsTaken();
  const unsetMutate = unsetTakenMutation.mutate;
  useEffect(() => {
    const unsetEvents = () => {
      unsetMutate({ verify_eventId: eventClickedRef.current });
    };

    // Also need to cleanup on tab closing
    window.addEventListener('beforeunload', unsetEvents);

    return () => {
      setAcceptNew(false);
      localStorage.setItem(
        'pcs_watch_settings',
        JSON.stringify({
          limit,
          acceptNew: false,
          lookback,
          autoExpire,
          pcsStatusThreshold,
          threatLevel,
          armstate,
        })
      );

      // When we leave the queue page - even to go to the verify page - we need to clean up watched events
      unsetEvents();
      window.removeEventListener('beforeunload', unsetEvents);
    };
  }, [eventClickedRef]);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityHandler);
    }

  }, [acceptNew]);

  // Use the custom hooks
  const { payload } = useQueueTimeMetrics({
    acceptNew,
    pcsStatusThreshold,
    threatLevel,
    armstate,
    eventClickedRef,
  });

  const { submitEventHandleTimeMetric } = useEventHandleTimeMetrics({
    eventId: eventClickedRef.current,
    acceptNew,
  });

  const { submitConcurrencyMetric } = useEventQueueConcurrencyMetric({
    events: eventArray2.eventArray,
  });

  const batchSubmitEventHandleTimeMetrics = (events: Array<unknown>) => {
    const batch = events.filter((event: any) => !!event.eventId);
    batch.forEach((event: any) => {
      submitEventHandleTimeMetric(event.eventId);
    });
  };

  const [eventsHandled, setEventsHandled] = React.useState(0);
  const { ...playerState } = useVideoPlayer(null, { isPlaying: true });
  if (repData?.role === Role.ENGINEER || repData?.role === Role.QA)
    return <Navigate to="/app/dashboard" />;
  if (!eventsQuery.isSuccess || !pcs_watch_settings.isSuccess)
    return <LoadingSpinner />;

  return (
    <Page className={classes.root} title="Queue">
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Grid
              container
              spacing={1}
              alignItems="stretch"
              justifyContent="center"
            >
              {eventArray2.eventArray?.map((event: any) => (
                <Grid
                  item
                  xs={limit === 1 ? 10 : limit === 3 ? 5 : 4}
                  key={`${event.handler.slot}-${event.eventId}`}
                  style={{
                    minHeight:
                      limit === 1
                        ? 'calc(90vh - 60px)'
                        : limit === 3
                        ? 'calc(50vh - 60px)'
                        : 'auto',
                  }}
                >
                  {event.eventId ? (
                    <MonitorItem
                      event={event}
                      slot={event.handler.slot}
                      playerState={playerState}
                      autoExpire={autoExpire}
                      onClose={() => {
                        submitConcurrencyMetric(event.eventId);
                        setEventsHandled(eventsHandled + 1);
                      }}
                      onPlayerClick={(eventId) => {
                        submitConcurrencyMetric(event.eventId);
                        batchSubmitEventHandleTimeMetrics(
                          eventArray2.eventArray
                        );
                        eventClickedRef.current = eventId;
                      }}
                      payload={payload.current}
                      experimental={experimental}
                      availableSlots={totalSlots - eventsPresented.length}
                      filledSlots={eventsPresented.length || 0}
                      handleAgentExited={handleAgentExited}
                    />
                  ) : (
                    <EmptyMonitorItem slot={event.handler.slot} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <QueueControls
            armstate={armstate}
            setArmstate={setArmstate}
            lookback={lookback}
            setLookback={setLookback}
            threatLevel={threatLevel}
            setThreatLevel={setThreatLevel}
            pcsStatusThreshold={pcsStatusThreshold}
            setPcsStatusThreshold={setPcsStatusThreshold}
            autoExpire={autoExpire}
            setAutoExpire={setAutoExpire}
            limit={limit}
            setLimit={setLimit}
            classes={classes}
            acceptNew={acceptNew}
            setAcceptNew={setAcceptNew}
            experimental={experimental}
            setExperimental={setExperimental}
            unassignedSlots={limit - eventsPresented.length}
            assignedSlots={eventsPresented.length || 0}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default MonitorView;
