export enum CAMERA_MODELS {
  SIMPLICAM = 'SS001',
  VIDEO_DOORBELL_PRO = 'SS002',
  SIMPLICAM1080 = 'SS003',
  SHIELD = 'SSOBCM4',
  DAGGER = 'SSOBCM4',
  SCOUT = 'SCOUT',
  OLYMPUS = 'OLYMPUS',
}

export const outdoorCameraModels = [
  CAMERA_MODELS.VIDEO_DOORBELL_PRO,
  CAMERA_MODELS.SHIELD,
  CAMERA_MODELS.OLYMPUS,
];
