import React from 'react';
import { MenuItem, Menu, Divider, ListItemText } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

export const DELIVERY = 'Delivery';
export const CANCELED_REP = 'canceled_rep';

export const selectItemsData = [
  { id: 1, value: 'Duplicate Event' },
  { id: 2, value: DELIVERY },
  { id: 3, value: 'No Person' },
  { id: 4, value: 'Passerby' },
  { id: 5, value: 'Outdoor Service' },
  { id: 6, value: 'Household Activity' },
  { id: 7, value: 'Technical Issue' },
  { id: 9, value: 'Weather Event' },
  { id: 10, value: 'Adjacent Activity' },
  { id: 8, value: 'Other' },
  { id: 12, value: 'Passerby in Car' },
  { id: 13, value: 'Camera Is Inside'},
];

export const cancelReasonMappping = {
  'Duplicate Event': '',
  'Delivery': '',
  'No Person': '',
  'Passerby': '',
  'Outdoor Service': '',
  'Household Activity': '',
  'Technical Issue': '',
  'Weather Event': '',
  'Adjacent Activity': '',
  'Other': '',
  'Passerby in Car': '',
  'Camera Is Inside': 'interior_camera_placement',
};

const CancelEventMenu = ({ anchorEl, open, close, select, excludeItems }) => {
  return (
    <Menu
      id="basic-menu"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          transform: 'translateY(-30px)',
        },
      }}
      anchorEl={anchorEl}
      open={open}
      style={{ marginTop: 40 }}
      onClose={close}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={close}>
        <ListItemText>Close</ListItemText>
        <Close fontSize="small" />
      </MenuItem>
      <Divider />

      {selectItemsData
        .filter((item) => !excludeItems.includes(item.value))
        .map((el) => (
          <MenuItem
            key={el.id}
            value={el.value}
            onClick={() =>
              select({
                action: CANCELED_REP,
                reason: el.value,
              })
            }
          >
            {el.value}
          </MenuItem>
        ))}
    </Menu>
  );
};

export default CancelEventMenu;
