import React from 'react';
import { Typography } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { getLocalDateFormatted } from '../../utils/time';

const CameraSignalHistory = ({ camera }) => {
  const { cameraSettings } = camera;
  const signalHistoryX = cameraSettings.admin.rssi.map((item: any) => getLocalDateFormatted(item[0]));
  const signalHistoryY = cameraSettings.admin.rssi.map((item: any) => item[1]);

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    title: {
      display: true,
      text: 'RSSI value in dBm',
      position: 'left',
      fontSize: '16',
    },
    scales: {
      yAxes: [{
        ticks: {
          max: 0,
          min: -100
        }
      }]
    }
  };

  const data = {
    labels: signalHistoryX,
    datasets: [
      {
        data: signalHistoryY,
        fill: false,
        borderColor: 'rgb(100, 141, 174)',
      },
    ],
  };
  return (
    <>
      <Typography
        variant="h2"
        style={{ color: 'rgba(255, 255, 255, 0.7)', marginBottom: 30 }}
      >
        Signal History
      </Typography>
      <Line options={options} data={data} />
    </>
  );
};

export default CameraSignalHistory;
